// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  materialmaster: {
    root: `/materialmaster`,
    // checkout: `/product/checkout`,
    // details: (id) => `/product/${id}`,
    // demo: {
    //   details: `/product/${MOCK_ID}`,
    // },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    posCheckingCheckout: `${ROOTS.DASHBOARD}/posCheckingCheckout`,
    posmainview: `${ROOTS.DASHBOARD}/posmainview`,
    possecondview: `${ROOTS.DASHBOARD}/possecondview`,
    cashCollectionreport: `${ROOTS.DASHBOARD}/cashCollectionreport`,
    posthirdview: `${ROOTS.DASHBOARD}/posthirdview`,
    posfourthview: `${ROOTS.DASHBOARD}/posfourthview`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
      pos: `${ROOTS.DASHBOARD}/pos`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    adminUser: {
      root: `${ROOTS.DASHBOARD}/adminUser`,
      new: `${ROOTS.DASHBOARD}/adminUser/new`,
      list: `${ROOTS.DASHBOARD}/adminUser/list`,
      cards: `${ROOTS.DASHBOARD}/adminUser/cards`,
      profile: (id) => `${ROOTS.DASHBOARD}/adminUser/${id}/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/usadminUserer/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/adminUser/${MOCK_ID}/edit`,
      },
    },
    vendor: {
      root: `${ROOTS.DASHBOARD}/vendor`,
      new: `${ROOTS.DASHBOARD}/vendor/new`,
      list: `${ROOTS.DASHBOARD}/vendor/list`,
      cards: `${ROOTS.DASHBOARD}/vendor/cards`,
      account: `${ROOTS.DASHBOARD}/vendor/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/vendor/${id}/edit`,
      profile: (id) => `${ROOTS.DASHBOARD}/vendor/${id}/profile`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/vendor/${MOCK_ID}/edit`,
      },
    },
    system: {
      root: `${ROOTS.DASHBOARD}/system`,
      new: `${ROOTS.DASHBOARD}/system/new`,
      purchaseorder: `${ROOTS.DASHBOARD}/system/purchaseorder`,
      grn: `${ROOTS.DASHBOARD}/system/grn`,
      list: `${ROOTS.DASHBOARD}/system/list`,
      cards: `${ROOTS.DASHBOARD}/system/cards`,
      profile: `${ROOTS.DASHBOARD}/system/profile`,
      account: `${ROOTS.DASHBOARD}/system/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/system/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/system/${MOCK_ID}/edit`,
      },
    },

    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    materialmaster: {
      root: `${ROOTS.DASHBOARD}/materialmaster`,
      new: `${ROOTS.DASHBOARD}/materialmaster/new`,
      newlist: `${ROOTS.DASHBOARD}/materialmaster/newlist`,
      // details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/materialmaster/${id}/edit`,
      newEdit: (id) => `${ROOTS.DASHBOARD}/materialmaster/${id}/newEdit`,
      view: (id) => `${ROOTS.DASHBOARD}/materialmaster/${id}/view`,
      newView: (id) => `${ROOTS.DASHBOARD}/materialmaster/${id}/newView`,
      demo: {
        details: `${ROOTS.DASHBOARD}/materialmaster/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/materialmaster/${MOCK_ID}/edit`,
      },
    },
    systremmaster: {
      root: `${ROOTS.DASHBOARD}/systremmaster`,
      definition: `${ROOTS.DASHBOARD}/systremmaster/definition`,
      // details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/systremmaster/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/systremmaster/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/systremmaster/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    report: {
      root: `${ROOTS.DASHBOARD}/report`,

      list: `${ROOTS.DASHBOARD}/report/list`,
      customerwise: `${ROOTS.DASHBOARD}/report/customerwise`,
      itemwise: `${ROOTS.DASHBOARD}/report/itemwise`,
      collection: `${ROOTS.DASHBOARD}/report/collection`,
      salesSummary: `${ROOTS.DASHBOARD}/report/salesSummary`,
      locationwisestockReport: `${ROOTS.DASHBOARD}/report/locationwisestockReport`,
      itemGroupwisestockReport: `${ROOTS.DASHBOARD}/report/itemGroupwisestockReport`,
      detailsStockReport: `${ROOTS.DASHBOARD}/report/detailsStockReport`,
      locationdetailsStockReport: `${ROOTS.DASHBOARD}/report/locationdetailsStockReport`,
      locationdetailsSalesSummary: `${ROOTS.DASHBOARD}/report/locationdetailsSalesSummary`,
      locationdetailsStockSummary: `${ROOTS.DASHBOARD}/report/locationdetailsStockSummary`,

      balancesheet: `${ROOTS.DASHBOARD}/report/balancesheet`,
      profitandloss: `${ROOTS.DASHBOARD}/report/profitandloss`,
      newlist: `${ROOTS.DASHBOARD}/report/newlist`,
      new: `${ROOTS.DASHBOARD}/report/new`,
      newtax: `${ROOTS.DASHBOARD}/report/newtax`,
      details: (id) => `${ROOTS.DASHBOARD}/report/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/report/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/report/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/report/${MOCK_ID}/edit`,
      },
    },
    chartofaccounts: {
      root: `${ROOTS.DASHBOARD}/chartofaccounts`,
      list: `${ROOTS.DASHBOARD}/chartofaccounts/list`,
      accountlist: `${ROOTS.DASHBOARD}/chartofaccounts/accountlist`,
      details: (id) => `${ROOTS.DASHBOARD}/chartofaccounts/${id}`,
      genaralledger: `${ROOTS.DASHBOARD}/chartofaccounts/genaralledger`,
      trialbalance: `${ROOTS.DASHBOARD}/chartofaccounts/trialbalance`,
      new: `${ROOTS.DASHBOARD}/chartofaccounts/new`,
      createnew: `${ROOTS.DASHBOARD}/chartofaccounts/createnew`,
      // newtax: `${ROOTS.DASHBOARD}/report/newtax`,
      // details: (id) => `${ROOTS.DASHBOARD}/report/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/report/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/report/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/report/${MOCK_ID}/edit`,
      // },
    },
    module: {
      new: `${ROOTS.DASHBOARD}/module/new`,
      taxList: `${ROOTS.DASHBOARD}/module/taxList`,
    },
    payment: {
      new: `${ROOTS.DASHBOARD}/payment/new`,
      list: `${ROOTS.DASHBOARD}/payment/list`,
    },
    erpinvoice: {
      root: `${ROOTS.DASHBOARD}/erpinvoice`,
      new: `${ROOTS.DASHBOARD}/erpinvoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/erpinvoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/erpinvoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/erpinvoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/erpinvoice/${MOCK_ID}/edit`,
      },
    },
    po: {
      root: `${ROOTS.DASHBOARD}/po`,
      new: `${ROOTS.DASHBOARD}/po/new`,
      details: (id) => `${ROOTS.DASHBOARD}/po/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/po/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/po/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/po/${MOCK_ID}/edit`,
      },
    },
    measurement: {
      root: `${ROOTS.DASHBOARD}/measurement`,
      new: `${ROOTS.DASHBOARD}/measurement/new`,
      details: (id) => `${ROOTS.DASHBOARD}/measurement/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/measurement/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/measurement/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/measurement/${MOCK_ID}/edit`,
      },
    },
    stockissuing: {
      root: `${ROOTS.DASHBOARD}/stockissuing`,
      issuedList: `${ROOTS.DASHBOARD}/stockissuing/issuedList`,
      new: `${ROOTS.DASHBOARD}/stockissuing/new`,
      details: (id) => `${ROOTS.DASHBOARD}/stockissuing/${id}`,
      stockIssuedDetails: (id) => `${ROOTS.DASHBOARD}/stockissuing/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/stockissuing/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/stockissuing/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/stockissuing/${MOCK_ID}/edit`,
      },
    },
    stockissued: {

      stockIssuedDetails: (id) => `${ROOTS.DASHBOARD}/stockissued/${id}`,

    },

    measurementview: {
      root: `${ROOTS.DASHBOARD}/measurementview`,
      // new: `${ROOTS.DASHBOARD}/measurement/new`,
      details: (id) => `${ROOTS.DASHBOARD}/measurementview/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/measurement/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/measurement/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/measurement/${MOCK_ID}/edit`,
      // },
    },
    grn: {
      root: `${ROOTS.DASHBOARD}/grn`,
      new: `${ROOTS.DASHBOARD}/grn/new`,
      directgrn: `${ROOTS.DASHBOARD}/grn/directgrn`,
      details: (id) => `${ROOTS.DASHBOARD}/grn/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/grn/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/grn/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/grn/${MOCK_ID}/edit`,
      },
    },
    systemMasterData: {
      root: `${ROOTS.DASHBOARD}/systemMasterData`,
      new: `${ROOTS.DASHBOARD}/systemMasterData/new`,
      masterDataMaster: `${ROOTS.DASHBOARD}/systemMasterData/masterDataMaster`,
      masterData: `${ROOTS.DASHBOARD}/systemMasterData/masterData`,
      adminUser: `${ROOTS.DASHBOARD}/systemMasterData/adminUser`,
      uom: `${ROOTS.DASHBOARD}/systemMasterData/uom`,
      personalDefinition: `${ROOTS.DASHBOARD}/systemMasterData/personalDefinition`,
      subAccounts: `${ROOTS.DASHBOARD}/systemMasterData/subAccounts`,
      subAcc: `${ROOTS.DASHBOARD}/systemMasterData/subAcc`,
      costCenter: `${ROOTS.DASHBOARD}/systemMasterData/costCenter`,
      float: `${ROOTS.DASHBOARD}/systemMasterData/float`,
      details: (id) => `${ROOTS.DASHBOARD}/systemMasterData/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/systemMasterData/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARDx}/systemMasterData/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/systemMasterData/${MOCK_ID}/edit`,
      },
    },

    measurementMaster: {
      root: `${ROOTS.DASHBOARD}/measurementMaster`,
      designNumber: `${ROOTS.DASHBOARD}/measurementMaster/designNumber`,
      relation: `${ROOTS.DASHBOARD}/measurementMaster/relation`,
      fit: `${ROOTS.DASHBOARD}/measurementMaster/fit`,
      sSeam: `${ROOTS.DASHBOARD}/measurementMaster/sSeam`,
      fundaLength: `${ROOTS.DASHBOARD}/measurementMaster/fundaLength`,
      noOfPkt: `${ROOTS.DASHBOARD}/measurementMaster/noOfPkt`,
      loop: `${ROOTS.DASHBOARD}/measurementMaster/loop`,
      back: `${ROOTS.DASHBOARD}/measurementMaster/back`,
      neckstyle: `${ROOTS.DASHBOARD}/measurementMaster/neckstyle`,
      half: `${ROOTS.DASHBOARD}/measurementMaster/half`,
      style: `${ROOTS.DASHBOARD}/measurementMaster/style`,
      shCut: `${ROOTS.DASHBOARD}/measurementMaster/shCUt`,
      pocketType: `${ROOTS.DASHBOARD}/measurementMaster/pocketType`,
      funda: `${ROOTS.DASHBOARD}/measurementMaster/funda`,
      dfType: `${ROOTS.DASHBOARD}/measurementMaster/dfType`,
      shType: `${ROOTS.DASHBOARD}/measurementMaster/shType`,
      kally: `${ROOTS.DASHBOARD}/measurementMaster/kally`,
      front: `${ROOTS.DASHBOARD}/measurementMaster/front`,
      neck: `${ROOTS.DASHBOARD}/measurementMaster/neck`,
      sleeve: `${ROOTS.DASHBOARD}/measurementMaster/sleeve`,
      darizType: `${ROOTS.DASHBOARD}/measurementMaster/darizType`,
    },

    darizDetailsMaster: {
      root: `${ROOTS.DASHBOARD}/darizDetailsMaster`,
      front: `${ROOTS.DASHBOARD}/darizDetailsMaster/front`,

    },

    pos: {
      erpUser: `${ROOTS.DASHBOARD}/posmainview/erpUser`,
      root: `${ROOTS.DASHBOARD}/posmainview/purchaseHistory`,
      return: `${ROOTS.DASHBOARD}/posmainview/returnBillHistory`,
      history: `${ROOTS.DASHBOARD}/possecondview/purchaseHistory`,
      discountHistory: `${ROOTS.DASHBOARD}/possecondview/purchaseDiscountHistory`,
      details: (id) => `${ROOTS.DASHBOARD}/possecondview/${id}`,
    },
    sales: {
      root: `${ROOTS.DASHBOARD}/cashCollectionreport/cashCollectionreportList`,
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
    salesOrder: {
      root: `${ROOTS.DASHBOARD}/salesOrder`,
      new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      },
    },
    cashandbank: {
      root: `${ROOTS.DASHBOARD}/cashandbank`,
      reimbursement: `${ROOTS.DASHBOARD}/cashandbank/reimbursement`,
      new: `${ROOTS.DASHBOARD}/cashandbank/new`,
      details: (id) => `${ROOTS.DASHBOARD}/cashandbank/${id}`,
      // details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    cashandbankpayment: {
      root: `${ROOTS.DASHBOARD}/cashandbankpayment`,
      new: `${ROOTS.DASHBOARD}/cashandbankpayment/new`,
      details: (id) => `${ROOTS.DASHBOARD}/cashandbankpayment/${id}`,
    },
    receipts: {
      root: `${ROOTS.DASHBOARD}/receipts`,
      new: `${ROOTS.DASHBOARD}/receipts/new`,
      details: (id) => `${ROOTS.DASHBOARD}/receipts/${id}`,
    },
    bankReceipts: {
      root: `${ROOTS.DASHBOARD}/bankReceipts`,
      new: `${ROOTS.DASHBOARD}/bankReceipts/new`,
      details: (id) => `${ROOTS.DASHBOARD}/bankReceipts/${id}`,
    },
    bill: {
      root: `${ROOTS.DASHBOARD}/bill`,
      new: `${ROOTS.DASHBOARD}/bill/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    cashSale: {
      root: `${ROOTS.DASHBOARD}/cashSale`,
      new: `${ROOTS.DASHBOARD}/cashSale/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    creditSale: {
      root: `${ROOTS.DASHBOARD}/creditSale`,
      new: `${ROOTS.DASHBOARD}/creditSale/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    outBoundDelivery: {
      root: `${ROOTS.DASHBOARD}/outBoundDelivery`,
      outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/outBoundDelivery/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    accountReceivable: {
      root: `${ROOTS.DASHBOARD}/accountReceivable`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/accountReceivable/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    posReceivable: {
      root: `${ROOTS.DASHBOARD}/posReceivable`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/posReceivable/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    accountPayble: {
      root: `${ROOTS.DASHBOARD}/accountPayble`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/accountPayble/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    postDatedChequeDetails: {
      root: `${ROOTS.DASHBOARD}/postDatedChequeDetails`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/postDatedChequeDetails/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    cashReceivable: {
      root: `${ROOTS.DASHBOARD}/cashReceivable`,
      receivedList: `${ROOTS.DASHBOARD}/cashReceivable/receivedList`,
      details: (id) => `${ROOTS.DASHBOARD}/cashReceivable/${id}`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/postDatedChequeDetails/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    cashReceived: {
      details: (id) => `${ROOTS.DASHBOARD}/cashReceived/${id}`,

    },
    stockTransferAccept: {
      root: `${ROOTS.DASHBOARD}/stockTransferAccept`,
      details: (id) => `${ROOTS.DASHBOARD}/stockTransferAccept/${id}`,
    },
    cashPayble: {
      root: `${ROOTS.DASHBOARD}/cashPayble`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/postDatedChequeDetails/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    postDatedPayble: {
      root: `${ROOTS.DASHBOARD}/postDatedPayble`,
      // outBoundlist: `${ROOTS.DASHBOARD}/outBoundDelivery/outBoundlist`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/postDatedChequeDetails/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    pickingList: {
      root: `${ROOTS.DASHBOARD}/pickingList`,
      pickingList: `${ROOTS.DASHBOARD}/pickingList/allpicking`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/pickingList/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    deliveryIn: {
      soInvoice: (id) => `${ROOTS.DASHBOARD}/deliveryIn/${id}`,
    },
    delivery: {
      root: `${ROOTS.DASHBOARD}/delivery`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/delivery/${id}`,

      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    outGoingpayment: {
      root: `${ROOTS.DASHBOARD}/outGoingpayment`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    inComingpayment: {
      root: `${ROOTS.DASHBOARD}/inComingpayment`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    stockMovement: {
      root: `${ROOTS.DASHBOARD}/stockMovement`,
      // new: `${ROOTS.DASHBOARD}/salesOrder/new`,
      details: (id) => `${ROOTS.DASHBOARD}/stockMovement/${id}`,
      stockLine: (id) => `${ROOTS.DASHBOARD}/stockLine/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    stockReport: {
      root: `${ROOTS.DASHBOARD}/stockReport`,
    },
    stockLine: {
      stockLine: (id, Batchid) => `${ROOTS.DASHBOARD}/stockLine/${id}/${Batchid}`,
    },
    price: {
      root: `${ROOTS.DASHBOARD}/price`,
      new: `${ROOTS.DASHBOARD}/price/new`,
      assignPrice: `${ROOTS.DASHBOARD}/price/assignPrice`,
      assignPriceList: `${ROOTS.DASHBOARD}/price/assignPriceList`,
      details: (id) => `${ROOTS.DASHBOARD}/price/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/price/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/price/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/price/${MOCK_ID}/edit`,
      },
    },
    category: {
      // root: `${ROOTS.DASHBOARD}/price`,
      new: `${ROOTS.DASHBOARD}/category/new`,
      // assignPrice: `${ROOTS.DASHBOARD}/price/assignPrice`,
      // details: (id) => `${ROOTS.DASHBOARD}/price/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/price/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/price/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/price/${MOCK_ID}/edit`,
      // },
    },
    manufacturing: {
      new: `${ROOTS.DASHBOARD}/manufacturing/new`,
      masterData: `${ROOTS.DASHBOARD}/manufacturing/masterData`,
    },
    creditMan: {
      root: `${ROOTS.DASHBOARD}/creditMan`,
      new: `${ROOTS.DASHBOARD}/creditMan/new`,
      details: (id) => `${ROOTS.DASHBOARD}/creditMan/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/creditMan/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/creditMan/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/creditMan/${MOCK_ID}/edit`,
      },
    },
    internalTransfer: {
      // root: `${ROOTS.DASHBOARD}/salesOrder`,
      new: `${ROOTS.DASHBOARD}/internalTransfer/new`,
      details: (id) => `${ROOTS.DASHBOARD}/internalTransfer/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    priceManagement: {
      new: `${ROOTS.DASHBOARD}/priceManagement/new`,
      list: `${ROOTS.DASHBOARD}/priceManagement/list`,
    },
    currencyManagement: {
      new: `${ROOTS.DASHBOARD}/currencyManagement/new`,
      list: `${ROOTS.DASHBOARD}/currencyManagement/list`,
      edit: (id) => `${ROOTS.DASHBOARD}/currencyManagement/${id}/edit`,
    },
    stockAdjustment: {
      // root: `${ROOTS.DASHBOARD}/salesOrder`,
      new: `${ROOTS.DASHBOARD}/stockAdjustment/new`,
      details: (id) => `${ROOTS.DASHBOARD}/stockAdjustment/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/salesOrder/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/salesOrder/${MOCK_ID}/edit`,
      // },
    },
    trialConfirmation: {
      root: `${ROOTS.DASHBOARD}/trialConfirmation`,
      confirmedlist: `${ROOTS.DASHBOARD}/trialConfirmation/confirmedlist`,
      details: (id) => `${ROOTS.DASHBOARD}/trialConfirmation/${id}`,
      confirmed: (id) => `${ROOTS.DASHBOARD}/trialConfirmation/${id}/confirmed`,
    },
    multipleStockTransfer: {
      root: `${ROOTS.DASHBOARD}/multipleStockTransfer`,
      new: `${ROOTS.DASHBOARD}/multipleStockTransfer/new`,
      details: (id) => `${ROOTS.DASHBOARD}/multipleStockTransfer/${id}`,
      accpt: (id) => `${ROOTS.DASHBOARD}/multipleStockTransfer/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/multipleStockTransfer/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/multipleStockTransfer/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/multipleStockTransfer/${MOCK_ID}/edit`,
      },
    },
    multipleStockTransferAccept: {
      details: (id) => `${ROOTS.DASHBOARD}/multipleStockTransferAccept/${id}`,
    },
  },
};
