// import { useMemo } from 'react';
// // routes
// import { paths } from 'src/routes/paths';
// // locales
// import { useLocales } from 'src/locales';
// // components
// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
// import SvgColor from 'src/components/svg-color';

// // redux
// import { useSelector } from 'src/redux/store';


// // ----------------------------------------------------------------------

// const icon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   // OR
//   // <Iconify icon="fluent:mail-24-filled" />
//   // https://icon-sets.iconify.design/solar/
//   // https://www.streamlinehq.com/icons
// );

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
//   setting: icon('ic_setting'),
//   admin: icon('ic_admin'),
//   newinvoice: icon('ic_invoice-add'),
//   grnBill: icon('ic_bill-check'),
//   purchaseOrder: icon('ic_purchase'),
//   price: icon('ic_price'),
//   stock: icon('ic_stock'),
//   credit: icon('ic_credit'),
//   receable: icon('ic_receive'),
//   payable: icon('ic_payable'),
//   cheque: icon('ic_cheque'),
//   cash: icon('ic_cash'),
//   report: icon('ic_report'),
//   reportbar: icon('ic_reportbar'),
//   configuration: icon('ic_configuration'),
//   setups: icon('ic_setup'),
//   pay: icon('ic_pay'),
//   bill: icon('ic_bill'),
//   customerInvoice: icon('ic_customerInvoice'),
//   salereceipts: icon('ic_salereceipts'),
//   manufacturing: icon('ic_manufact'),
//   bank: icon('ic_bank'),
//   money: icon('ic_money'),
//   bankReceipt: icon('ic_bankReceipts'),
//   stockAdjustment: icon('ic_stockAdjustmet'),
// };

// // ----------------------------------------------------------------------

// export function useNavData() {
//   const { t } = useLocales();
//   const userRole = useSelector((state) => state.auth.user.roles[0]);
//   console.log("FIREUSER", userRole);
//   const data = useMemo(
//     () => [
//       // OVERVIEW
//       // ----------------------------------------------------------------------
//       {
//         subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('ERP') : null,
//         // items: [
//         //   // { title: t('dashboard'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },
//         //   { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
//         //   // { title: t('analytics'), path: paths.dashboard.general.analytics, icon: ICONS.analytics },
//         //   // { title: t('banking'), path: paths.dashboard.general.banking, icon: ICONS.banking },
//         //   // { title: t('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },
//         //   // { title: t('file'), path: paths.dashboard.general.file, icon: ICONS.file },
//         // ],


//         items: [
//           userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard } : null
//         ].filter(Boolean),
//       },

//       // {
//       //   subheader: t('ERP'),
//       //   items: [
//       //     {
//       //       title: t('Material Master'),
//       //       path: paths.dashboard.general.ecommerce,
//       //       icon: ICONS.setting,
//       //       children: [
//       //         { title: t('Master Data'), path: paths.dashboard.materialmaster.new },
//       //         { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
//       //       ],
//       //     },
//       //   ],
//       // },

//       // ----------------------------------------------------------------------

//       // {
//       //   subheader: t('Point of Sales'),
//       //   items: [
//       //     {
//       //       title: t('Point of Sales'),
//       //       path: paths.dashboard.general.ecommerce,
//       //       icon: ICONS.ecommerce,
//       //       children: [
//       //         { title: t('dashboard'), path: paths.dashboard.general.pos },
//       //         { title: t('Add Product'), path: paths.dashboard.product.root },
//       //         { title: t('Checkout'), path: paths.dashboard.general.analytics },
//       //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
//       //         { title: t('cards'), path: paths.dashboard.user.cards },
//       //         { title: t('account'), path: paths.dashboard.user.account },
//       //       ],
//       //     },
//       //   ],
//       // },

//       // {
//       //   items: [{ title: t('POS'), path: paths.dashboard.general.pos, icon: ICONS.ecommerce }],
//       // },

//       // POS
//       // {
//       //   subheader: t('pos'),
//       //   items: [
//       //     {
//       //       title: t('POS'),
//       //       path: paths.dashboard.possecondview,
//       //       icon: ICONS.ecommerce,
//       //       // children: [
//       //       //   // { title: t('POS Main View'), path: paths.dashboard.posmainview },
//       //       //   { title: t('POS Second View'), path: paths.dashboard.possecondview },
//       //       //   // { title: t('POS Third View'), path: paths.dashboard.posthirdview },
//       //       //   // { title: t('POS Fourth View'), path: paths.dashboard.posfourthview },
//       //       // ],
//       //     },
//       //     {
//       //       title: t('Measurement'),
//       //       path: paths.dashboard.measurement.root,
//       //       icon: ICONS.ecommerce,
//       //       children: [
//       //         { title: t('Create Measurement'), path: paths.dashboard.measurement.new },
//       //         { title: t('Measurement List'), path: paths.dashboard.measurement.root },
//       //         // { title: t('POS Third View'), path: paths.dashboard.posthirdview },
//       //         // { title: t('POS Fourth View'), path: paths.dashboard.posfourthview },
//       //       ],
//       //     },
//       //   ],
//       // },

//       // POS REPORT
//       // {
//       //   subheader: t('pos report'),
//       //   items: [
//       //     // {
//       //     //   title: t('POS'),
//       //     //   path: paths.dashboard.sampleDashboard,
//       //     //   icon: ICONS.ecommerce,
//       //     // },
//       //     {
//       //       title: t('pos payment receivable'),
//       //       path: paths.dashboard.posReceivable.root,
//       //       icon: ICONS.ecommerce,
//       //     },
//       //     {
//       //       title: t('purchase History'),
//       //       path: paths.dashboard.pos.root,
//       //       icon: ICONS.ecommerce,
//       //     },
//       //     {
//       //       title: t('return bill History'),
//       //       path: paths.dashboard.pos.return,
//       //       icon: ICONS.ecommerce,
//       //     },
//       //     // {
//       //     //   title: t('sampleDashboard'),
//       //     //   path: paths.dashboard.pos.erpUser,
//       //     //   icon: ICONS.ecommerce,
//       //     // },
//       //   ],
//       // },

//       // ----------------------------------------------------------------------

//       // Replenishment
//       {
//         subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('Replenishment') : null,
//         items: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? [
//           {
//             // VENDOR
//             title: t('Business Partners'),
//             path: paths.dashboard.vendor.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('Business Partners creation '), path: paths.dashboard.vendor.new },
//               { title: t('Business Partners list'), path: paths.dashboard.vendor.list },
//               // { title: t('vendor profile'), path: paths.dashboard.vendor.root },
//               // { title: t('edit vendor'), path: paths.dashboard.vendor.demo.edit },
//               // { title: t('vendor account'), path: paths.dashboard.vendor.account },
//               // { title: t('vendor profile'), path: paths.dashboard.vendor.root },
//               // { title: t('cards'), path: paths.dashboard.vendor.cards },
//             ],
//           },
//           // PO
//           // {
//           //   title: t('purchase order'),
//           //   path: paths.dashboard.po.root,
//           //   icon: ICONS.purchaseOrder,
//           //   children: [
//           //     { title: t('PO creation'), path: paths.dashboard.po.new },
//           //     { title: t('PO list'), path: paths.dashboard.po.root },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//           //   ],
//           // },
//           // GRN
//           // {
//           //   title: t('GRN'),
//           //   path: paths.dashboard.grn.root,
//           //   icon: ICONS.grnBill,
//           //   children: [
//           //     { title: t('grn creation'), path: paths.dashboard.grn.new },
//           //     { title: t('grn list'), path: paths.dashboard.grn.root },
//           //     // { title: t('direct grn creation'), path: paths.dashboard.grn.directgrn },
//           //     // { title: t('edit'), path: paths.dashboard.grn.demo.edit },
//           //     // { title: t('details'), path: paths.dashboard.grn.demo.details },
//           //   ],
//           // },
//           // INVOICE
//           // {
//           //   title: t('Invoice'),
//           //   path: paths.dashboard.erpinvoice.root,
//           //   icon: ICONS.newinvoice,
//           //   children: [
//           //     { title: t('invoice creation'), path: paths.dashboard.erpinvoice.new },
//           //     { title: t('invoice list'), path: paths.dashboard.erpinvoice.root },
//           //     // { title: t('details'), path: paths.dashboard.erpinvoice.demo.details },

//           //     // { title: t('edit'), path: paths.dashboard.erpinvoice.demo.edit },
//           //   ],
//           // },
//           // Payments
//           {
//             title: t('Petty Cash'),
//             path: paths.dashboard.cashandbank.root,
//             icon: ICONS.pay,
//             children: [
//               { title: t('Petty Cash Payment'), path: paths.dashboard.cashandbank.new },
//               { title: t('Petty Cash Payment list'), path: paths.dashboard.cashandbank.root },
//               // { title: t('Petty Cash Reimbursement'), path: paths.dashboard.cashandbank.reimbursement },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//             ],
//           },
//           {
//             title: t('Bank Payment'),
//             path: paths.dashboard.cashandbankpayment.root,
//             icon: ICONS.bank,
//             children: [
//               { title: t('Payment Voucher'), path: paths.dashboard.cashandbankpayment.new },
//               { title: t('Payment Voucher list'), path: paths.dashboard.cashandbankpayment.root },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//             ],
//           },
//           {
//             title: t('Cash Receipts'),
//             path: paths.dashboard.receipts.root,
//             icon: ICONS.money,
//             children: [
//               { title: t('Cash Receipts'), path: paths.dashboard.receipts.new },
//               { title: t('Cash Receipts list'), path: paths.dashboard.receipts.root },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//             ],
//           },
//           {
//             title: t('Bank Receipts'),
//             path: paths.dashboard.bankReceipts.root,
//             icon: ICONS.bankReceipt,
//             children: [
//               { title: t('Bank Receipts'), path: paths.dashboard.bankReceipts.new },
//               { title: t('Bank Receipts list'), path: paths.dashboard.bankReceipts.root },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('details'), path: paths.dashboard.po.demo.details },
//               // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//             ],
//           },

//           // {
//           //   title: t('cash & bank'),
//           //   path: paths.dashboard.cashandbank.new,
//           //   icon: ICONS.pay,
//           //   children: [
//           //     {
//           //       title: t('Petty Cash'),
//           //       path:  paths.dashboard.cashandbank.new,
//           //       children: [
//           //         {
//           //           title: t('Petty Cash Payment'),
//           //           path: paths.dashboard.cashandbank.new,
//           //         },
//           //         {
//           //           title: t('Petty Cash Payment list'),
//           //           path: paths.dashboard.cashandbank.root,
//           //         },
//           //       ],
//           //     },
//           //     {
//           //       title: t('Cash & Bank Payment'),
//           //       path: paths.dashboard.cashandbank.new,
//           //       children: [
//           //         {
//           //           title: t('Cash & Bank Payment'),
//           //           path: paths.dashboard.cashandbank.new,
//           //         },
//           //         {
//           //           title: t('Cash & Bank Payment List'),
//           //           path: paths.dashboard.cashandbank.root,
//           //         },
//           //       ],
//           //     },
//           //   ],
//           // },

//           // Bill
//           // {
//           //   title: t('Vendor bill'),
//           //   path: paths.dashboard.bill.root,
//           //   icon: ICONS.bill,
//           //   children: [
//           //     { title: t('bill Creation'), path: paths.dashboard.bill.new },
//           //     { title: t('bill list'), path: paths.dashboard.bill.root },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//           //   ],
//           // },

//           // Out Going Payment
//           // {
//           //   title: t('out Going Payment'),
//           //   path: paths.dashboard.outGoingpayment.root,
//           //   icon: ICONS.purchaseOrder,
//           //   children: [
//           //     // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//           //     { title: t('out going payment'), path: paths.dashboard.outGoingpayment.root },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//           //   ],
//           // },
//         ] : [],
//       },

//       // SD Module
//       // {
//       //   subheader: 'Sales & Distribution',
//       //   items: [
//       //     // {
//       //     //   title: t('report'),
//       //     //   path: paths.dashboard.report.root,
//       //     //   icon: ICONS.invoice,
//       //     //   children: [
//       //     //     { title: t('list'), path: paths.dashboard.report.root },
//       //     //     { title: t('newlist'), path: paths.dashboard.report.newlist },
//       //     //     { title: t('details'), path: paths.dashboard.report.demo.details },
//       //     //     { title: t('create'), path: paths.dashboard.report.new },
//       //     //     { title: t('edit'), path: paths.dashboard.report.demo.edit },
//       //     //   ],
//       //     // },
//       //     // Sales Order
//       //     {
//       //       title: t('sales order'),
//       //       path: paths.dashboard.salesOrder.root,
//       //       icon: ICONS.purchaseOrder,
//       //       children: [
//       //         { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//       //         { title: t('sales order list'), path: paths.dashboard.salesOrder.root },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },

//       //     // Out Bound Delivery
//       //     {
//       //       title: t('out Bound Delivery'),
//       //       path: paths.dashboard.outBoundDelivery.root,
//       //       icon: ICONS.purchaseOrder,
//       //       children: [
//       //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//       //         { title: t('out bound delivery'), path: paths.dashboard.outBoundDelivery.root },
//       //         {
//       //           title: t('out bound delivery list'),
//       //           path: paths.dashboard.outBoundDelivery.outBoundlist,
//       //         },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },
//       //     // Picking List
//       //     {
//       //       title: t('picking list'),
//       //       path: paths.dashboard.pickingList.root,
//       //       icon: ICONS.purchaseOrder,
//       //       children: [
//       //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//       //         { title: t('picking list'), path: paths.dashboard.pickingList.root },
//       //         { title: t('all picking list'), path: paths.dashboard.pickingList.pickingList },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },
//       //     // Delivery
//       //     {
//       //       title: t('order delivery'),
//       //       path: paths.dashboard.delivery.root,
//       //       icon: ICONS.purchaseOrder,
//       //       children: [
//       //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//       //         { title: t('delivery list'), path: paths.dashboard.delivery.root },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },
//       //     // SALE RECEIPTS
//       //     {
//       //       title: t('sale receipts'),
//       //       path: paths.dashboard.cashSale.root,
//       //       icon: ICONS.salereceipts,
//       //       children: [
//       //         { title: t('enter sale receipts'), path: paths.dashboard.cashSale.new },
//       //         { title: t('sale receipt list'), path: paths.dashboard.cashSale.root },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },
//       //     // CUSTOMER INVOICE
//       //     {
//       //       title: t('create invoices'),
//       //       path: paths.dashboard.creditSale.root,
//       //       icon: ICONS.customerInvoice,
//       //       children: [
//       //         { title: t('customer invoice'), path: paths.dashboard.creditSale.new },
//       //         { title: t('customer invoice list'), path: paths.dashboard.creditSale.root },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },
//       //     // In Coming Payment
//       //     // {
//       //     //   title: t('in coming payment'),
//       //     //   path: paths.dashboard.inComingpayment.root,
//       //     //   icon: ICONS.purchaseOrder,
//       //     //   children: [
//       //     //     // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//       //     //     { title: t('in coming payment'), path: paths.dashboard.inComingpayment.root },
//       //     //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //     //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //     //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //     //   ],
//       //     // },
//       //   ],
//       // },

//       // // Pricing Module
//       // {
//       //   subheader: t('Pricing Module'),
//       //   items: [
//       //     {
//       //       title: t('Create price'),
//       //       path: paths.dashboard.price.root,
//       //       icon: ICONS.price,
//       //       children: [
//       //         { title: t('create price'), path: paths.dashboard.price.new },
//       //         // { title: t('Price Assign & List'), path: paths.dashboard.price.root },
//       //         // { title: t('Whole Sale Price & List'), path: paths.dashboard.price.assignPrice },

//       //         // { title: t('details'), path: paths.dashboard.price.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.price.demo.edit },
//       //       ],
//       //     },
//       //     {
//       //       title: t('Price Assign'),
//       //       path: paths.dashboard.price.root,
//       //       icon: ICONS.price,
//       //       children: [
//       //         { title: t('Price Assign'), path: paths.dashboard.price.root },
//       //         { title: t('Price Assign List'), path: paths.dashboard.price.assignPriceList },
//       //         // { title: t('Whole Sale Price & List'), path: paths.dashboard.price.assignPrice },
//       //         // { title: t('details'), path: paths.dashboard.price.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.price.demo.edit },
//       //       ],
//       //     },
//       //     {
//       //       title: t('Customer Assign'),
//       //       path: paths.dashboard.price.root,
//       //       icon: ICONS.price,
//       //       children: [
//       //         // { title: t('create price'), path: paths.dashboard.price.new },
//       //         // { title: t('Price Assign & List'), path: paths.dashboard.price.root },
//       //         { title: t('Customer Assign'), path: paths.dashboard.price.assignPrice },
//       //         // { title: t('details'), path: paths.dashboard.price.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.price.demo.edit },
//       //       ],
//       //     },
//       //   ],
//       // },

//       // FInance Module
//       {
//         subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('Finance Module') : null,
//         items: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? [
//           // {
//           //   title: t('credit Management'),
//           //   path: paths.dashboard.creditMan.root,
//           //   icon: ICONS.credit,
//           //   children: [
//           //     { title: t('Define Credit Management'), path: paths.dashboard.creditMan.new },
//           //     { title: t('List of Credit'), path: paths.dashboard.creditMan.root },
//           //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
//           //   ],
//           // },
//           // {
//           //   title: t('account Receivable'),
//           //   path: paths.dashboard.accountReceivable.root,
//           //   icon: ICONS.receable,
//           //   children: [
//           //     // { title: t('create'), path: paths.dashboard.creditMan.new },
//           //     { title: t('A/R list'), path: paths.dashboard.accountReceivable.root },
//           //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
//           //   ],
//           // },
//           // {
//           //   title: t('account Payble'),
//           //   path: paths.dashboard.accountPayble.root,
//           //   icon: ICONS.payable,
//           //   children: [
//           //     // { title: t('create'), path: paths.dashboard.creditMan.new },
//           //     { title: t('A/P list'), path: paths.dashboard.accountPayble.root },
//           //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
//           //   ],
//           // },
//           // {
//           //   title: t('cheque details'),
//           //   path: paths.dashboard.postDatedChequeDetails.root,
//           //   icon: ICONS.cheque,
//           //   children: [
//           //     // { title: t('create'), path: paths.dashboard.creditMan.new },
//           //     {
//           //       title: t('Post Dated Receivable'),
//           //       path: paths.dashboard.postDatedChequeDetails.root,
//           //     },
//           //     { title: t('Post Dated Payble'), path: paths.dashboard.postDatedPayble.root },
//           //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
//           //   ],
//           // },
//           // {
//           //   title: t('cash details'),
//           //   path: paths.dashboard.cashReceivable.root,
//           //   icon: ICONS.cash,
//           //   children: [
//           //     // { title: t('create'), path: paths.dashboard.creditMan.new },
//           //     {
//           //       title: t('Cash Receivable'),
//           //       path: paths.dashboard.cashReceivable.root,
//           //     },
//           //     { title: t('Cash Payble'), path: paths.dashboard.cashPayble.root },
//           //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
//           //   ],
//           // },

//           // {
//           //   title: t('statement report'),
//           //   path: paths.dashboard.report.root,
//           //   icon: ICONS.report,
//           //   children: [
//           //     {
//           //       title: t('balance sheet'),
//           //       path: paths.dashboard.report.balancesheet,
//           //     },
//           //     {
//           //       title: t('profit and loss'),
//           //       path: paths.dashboard.report.profitandloss,
//           //     },
//           //     {
//           //       title: t('cash flow statement'),
//           //       path: '#/dashboard/menu_level/menu_level_1a',
//           //     },
//           //     {
//           //       title: t('executive summary'),
//           //       path: '#/dashboard/menu_level/menu_level_1a',
//           //     },
//           //     {
//           //       title: t('Tax report'),
//           //       // path: paths.dashboard.report.newtax,
//           //       path: '#/dashboard/menu_level/menu_level_1a',
//           //     },
//           //   ],
//           // },
//           // {
//           //   title: t('summary report'),
//           //   path: '#/dashboard/menu_level',
//           //   icon: ICONS.reportbar,
//           //   children: [
//           //     {
//           //       title: t('general ledger'),
//           //       path: paths.dashboard.chartofaccounts.genaralledger,
//           //     },
//           //     {
//           //       title: t('trial balance'),
//           //       path: paths.dashboard.chartofaccounts.trialbalance,
//           //     },
//           //     // {
//           //     //   title: t('journal report'),
//           //     //   path: '#/dashboard/menu_level/menu_level_1a',
//           //     // },
//           //   ],
//           // },
//           // {
//           //   title: t('configurations'),
//           //   path: '#/dashboard/menu_level',
//           //   icon: ICONS.configuration,
//           //   children: [
//           //     {
//           //       title: t('module setup'),
//           //       path: paths.dashboard.module.new,
//           //     },
//           //     // {
//           //     //   title: t('trial balance'),
//           //     //   path: '#/dashboard/menu_level/menu_level_1a',
//           //     // },
//           //     // {
//           //     //   title: t('journal report'),
//           //     //   path: '#/dashboard/menu_level/menu_level_1a',
//           //     // },
//           //   ],
//           // },

//           {
//             title: t('configurations'),
//             path: '#/dashboard/menu_level',
//             icon: ICONS.setups,
//             children: [
//               {
//                 title: t('module setup'),
//                 path: paths.dashboard.module.new,
//               },
//               {
//                 title: t('CA accounts setup'),
//                 path: '#/dashboard/menu_level/menu_level_1b',
//                 children: [
//                   {
//                     title: t('create chart of accounts'),
//                     path: paths.dashboard.chartofaccounts.new,
//                   },
//                   {
//                     title: t('chart of accounts'),
//                     path: paths.dashboard.chartofaccounts.list,
//                   },
//                   // {
//                   //   title: t('menu_level_2b'),
//                   //   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
//                   //   children: [
//                   //     {
//                   //       title: t('menu_level_3a'),
//                   //       path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
//                   //     },
//                   //     {
//                   //       title: t('menu_level_3b'),
//                   //       path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
//                   //     },
//                   //   ],
//                   // },
//                 ],
//               },
//             ],
//           },
//           {
//             title: t('chart of accounts'),
//             path: '#/dashboard/menu_level',
//             icon: ICONS.configuration,
//             children: [
//               {
//                 title: t('create new accounts'),
//                 path: paths.dashboard.chartofaccounts.createnew,
//               },
//               {
//                 title: t('Journal list'),
//                 path: paths.dashboard.chartofaccounts.accountlist,
//               },
//               // {
//               //   title: t('chart of accounts'),
//               //   path: paths.dashboard.chartofaccounts.list,
//               // },

//               // {
//               //   title: t('trial balance'),
//               //   path: '#/dashboard/menu_level/menu_level_1a',
//               // },
//               // {
//               //   title: t('journal report'),
//               //   path: '#/dashboard/menu_level/menu_level_1a',
//               // },
//             ],
//           },
//           // Payments
//           // {
//           //   title: t('cash & bank'),
//           //   path: paths.dashboard.cashandbank.root,
//           //   icon: ICONS.pay,
//           //   children: [
//           //     { title: t('payment Creation'), path: paths.dashboard.cashandbank.new },
//           //     { title: t('payment list'), path: paths.dashboard.cashandbank.root },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//           //   ],
//           // },
//           // // Bill
//           // {
//           //   title: t('bill'),
//           //   path: paths.dashboard.bill.root,
//           //   icon: ICONS.bill,
//           //   children: [
//           //     { title: t('bill Creation'), path: paths.dashboard.bill.new },
//           //     { title: t('bill list'), path: paths.dashboard.bill.root },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('details'), path: paths.dashboard.po.demo.details },
//           //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//           //   ],
//           // },
//           // {
//           //   title: t('Payments'),
//           //   path: '#/dashboard/menu_level',
//           //   icon: ICONS.pay,
//           //   children: [
//           //     {
//           //       title: t('create payments'),
//           //       path: paths.dashboard.payment.new,
//           //     },
//           //     {
//           //       title: t('payment list'),
//           //       path: paths.dashboard.payment.list,
//           //     },
//           //     // {
//           //     //   title: t('accounts list'),
//           //     //   path: paths.dashboard.chartofaccounts.accountlist,
//           //     // },
//           //     // {
//           //     //   title: t('chart of accounts'),
//           //     //   path: paths.dashboard.chartofaccounts.list,
//           //     // },
//           //     // {
//           //     //   title: t('create chart of accounts'),
//           //     //   path: paths.dashboard.chartofaccounts.new,
//           //     // },
//           //     // {
//           //     //   title: t('trial balance'),
//           //     //   path: '#/dashboard/menu_level/menu_level_1a',
//           //     // },
//           //     // {
//           //     //   title: t('journal report'),
//           //     //   path: '#/dashboard/menu_level/menu_level_1a',
//           //     // },
//           //   ],
//           // },
//         ] : [],
//       },
//       // {
//       //   subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('Master Data') : null,
//       //   items: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? [
//       //     {
//       //       title: t('Material Master'),
//       //       path: paths.dashboard.general.ecommerce,
//       //       icon: ICONS.setting,
//       //       children: [
//       //         { title: t('Master Data'), path: paths.dashboard.materialmaster.new },
//       //         { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
//       //         // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
//       //         { title: t('Create Product Category'), path: paths.dashboard.category.new },
//       //       ],
//       //     },
//       //   ] : [],
//       // },
//       // {
//       //   subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('Manufacturing') : null,
//       //   items: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? [
//       //     {
//       //       title: t('Manufacturing'),
//       //       path: paths.dashboard.general.ecommerce,
//       //       icon: ICONS.manufacturing,
//       //       children: [
//       //         { title: t('Production Capacity'), path: paths.dashboard.manufacturing.new },
//       //         { title: t('Define Delivery & Trial Date'), path: paths.dashboard.manufacturing.masterData },
//       //         // { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
//       //         // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
//       //         // { title: t('Create Product Category'), path: paths.dashboard.category.new },
//       //       ],
//       //     },
//       //     // {
//       //     //   title: t('Measurement Print'),
//       //     //   path: paths.dashboard.general.ecommerce,
//       //     //   icon: ICONS.manufacturing,
//       //     //   children: [
//       //     //     { title: t('Print Measurement Sheet'), path: paths.dashboard.measurementview.root },
//       //     //     // { title: t('Manufacturing Master Data'), path: paths.dashboard.manufacturing.masterData },
//       //     //     // { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
//       //     //     // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
//       //     //     // { title: t('Create Product Category'), path: paths.dashboard.category.new },
//       //     //   ],
//       //     // },
//       //   ] : [],
//       // },
//       // {
//       //   subheader: t('Stock Transfer'),
//       //   items: [
//       //     {
//       //       title: t('Stock Transfer'),
//       //       path: paths.dashboard.general.ecommerce,
//       //       icon: ICONS.stock,
//       //       children: [
//       //         { title: t('Stock Transfer'), path: paths.dashboard.internalTransfer.new },
//       //         // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
//       //       ],
//       //     },
//       //   ],
//       // },
//       // {
//       //   subheader: t('Stock Adjusment'),
//       //   items: [
//       //     {
//       //       title: t('Stock Adjusment'),
//       //       path: paths.dashboard.general.ecommerce,
//       //       icon: ICONS.stockAdjustment,
//       //       children: [
//       //         { title: t('Stock Adjusment'), path: paths.dashboard.stockAdjustment.new },
//       //         // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
//       //       ],
//       //     },
//       //   ],
//       // },

//       // SETTINGS
//       {
//         subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('Settings') : null,
//         items: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? [
//           // {
//           //   title: t('System Settings'),
//           //   path: paths.dashboard.system.root,
//           //   icon: ICONS.setting,
//           //   children: [
//           //     { title: t('Products Feilds'), path: paths.dashboard.system.new },
//           //     // { title: t('Purchase Order'), path: paths.dashboard.system.purchaseorder },
//           //     // { title: t('GRN'), path: paths.dashboard.system.grn },
//           //     // { title: t('profile'), path: paths.dashboard.system.root },
//           //     // { title: t('cards'), path: paths.dashboard.system.cards },
//           //     // { title: t('list'), path: paths.dashboard.system.list },
//           //     // { title: t('edit'), path: paths.dashboard.system.demo.edit },
//           //     // { title: t('account'), path: paths.dashboard.system.account },
//           //   ],
//           // },

//           // // INVOICE
//           // {
//           //   title: t('invoice'),
//           //   path: paths.dashboard.invoice.root,
//           //   icon: ICONS.invoice,
//           //   children: [
//           //     { title: t('list'), path: paths.dashboard.invoice.root },
//           //     { title: t('details'), path: paths.dashboard.invoice.demo.details },
//           //     { title: t('create'), path: paths.dashboard.invoice.new },
//           //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
//           //   ],
//           // },

//           // System Master Data
//           // {
//           //   title: t('System Master'),
//           //   path: paths.dashboard.general.ecommerce,
//           //   icon: ICONS.setting,
//           //   children: [
//           //     { title: t('System Definition'), path: paths.dashboard.systremmaster.definition },
//           //   ],
//           // },
//           // System Master
//           {
//             title: t('System Master'),
//             path: paths.dashboard.systemMasterData.root,
//             icon: ICONS.setting,
//             children: [
//               { title: t('system'), path: paths.dashboard.systemMasterData.new },
//               // { title: t('master data'), path: paths.dashboard.systemMasterData.masterData },
//               { title: t('system location'), path: paths.dashboard.systemMasterData.adminUser },
//               { title: t('UOM'), path: paths.dashboard.systemMasterData.uom },
//               { title: t('Honorific Names'), path: paths.dashboard.systemMasterData.personalDefinition },
//               { title: t('Cost centers'), path: paths.dashboard.systemMasterData.costCenter },
//               { title: t('Sub Accounts Type'), path: paths.dashboard.systemMasterData.subAccounts },
//               { title: t('Sub Accounts'), path: paths.dashboard.systemMasterData.subAcc },

//               // { title: t('Petty cash float'), path: paths.dashboard.systemMasterData.float },

//               // { title: t('details'), path: paths.dashboard.systemMasterData.demo.details },
//               // { title: t('list'), path: paths.dashboard.systemMasterData.root },
//               // { title: t('edit'), path: paths.dashboard.systemMasterData.demo.edit },
//             ],
//           },
//           // USER
//           {
//             title: t('system users'),
//             path: paths.dashboard.adminUser.root,
//             icon: ICONS.admin,
//             children: [
//               { title: t('New System User'), path: paths.dashboard.adminUser.new },
//               { title: t('System User List'), path: paths.dashboard.adminUser.list },
//               // { title: t('edit'), path: paths.dashboard.adminUser.demo.edit },
//               // { title: t('profile'), path: paths.dashboard.adminUser.root },
//               // { title: t('cards'), path: paths.dashboard.adminUser.cards },
//               // { title: t('account'), path: paths.dashboard.user.account },
//             ],
//           },
//         ] : [],
//       },

//       // Report
//       // {
//       //   subheader: 'Report',
//       //   items: [
//       //     // {
//       //     //   title: t('report'),
//       //     //   path: paths.dashboard.report.root,
//       //     //   icon: ICONS.invoice,
//       //     //   children: [
//       //     //     { title: t('list'), path: paths.dashboard.report.root },
//       //     //     { title: t('newlist'), path: paths.dashboard.report.newlist },
//       //     //     { title: t('details'), path: paths.dashboard.report.demo.details },
//       //     //     { title: t('create'), path: paths.dashboard.report.new },
//       //     //     { title: t('edit'), path: paths.dashboard.report.demo.edit },
//       //     //   ],
//       //     // },
//       //     // Stock Movement
//       //     {
//       //       title: t('stock Movement'),
//       //       path: paths.dashboard.stockMovement.root,
//       //       icon: ICONS.purchaseOrder,
//       //       children: [
//       //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
//       //         { title: t('Stock report'), path: paths.dashboard.stockReport.root },
//       //         { title: t('Stock movement report'), path: paths.dashboard.stockMovement.root },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('details'), path: paths.dashboard.po.demo.details },
//       //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
//       //       ],
//       //     },
//       //   ],
//       // },

//       // MANAGEMENT
//       // ----------------------------------------------------------------------
//       // {
//       //   subheader: t('management'),
//       //   items: [
//       //     // USER
//       //     {
//       //       title: t('user'),
//       //       path: paths.dashboard.user.root,
//       //       icon: ICONS.user,
//       //       children: [
//       //         { title: t('profile'), path: paths.dashboard.user.root },
//       //         { title: t('cards'), path: paths.dashboard.user.cards },
//       //         { title: t('list'), path: paths.dashboard.user.list },
//       //         { title: t('create'), path: paths.dashboard.user.new },
//       //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
//       //         { title: t('account'), path: paths.dashboard.user.account },
//       //       ],
//       //     },

//       //     // PRODUCT
//       //     {
//       //       title: t('product'),
//       //       path: paths.dashboard.product.root,
//       //       icon: ICONS.product,
//       //       children: [
//       //         { title: t('list'), path: paths.dashboard.product.root },
//       //         { title: t('details'), path: paths.dashboard.product.demo.details },
//       //         { title: t('create'), path: paths.dashboard.product.new },
//       //         { title: t('edit'), path: paths.dashboard.product.demo.edit },
//       //       ],
//       //     },

//       //     // Material Master
//       //     {
//       //       title: t('materialmaster'),
//       //       path: paths.dashboard.materialmaster.root,
//       //       icon: ICONS.product,
//       //       children: [
//       //         { title: t('list'), path: paths.dashboard.materialmaster.root },
//       //         { title: t('details'), path: paths.dashboard.materialmaster.demo.details },
//       //         { title: t('create'), path: paths.dashboard.materialmaster.new },
//       //         { title: t('edit'), path: paths.dashboard.materialmaster.demo.edit },
//       //       ],
//       //     },

//       //     // ORDER
//       //     {
//       //       title: t('order'),
//       //       path: paths.dashboard.order.root,
//       //       icon: ICONS.order,
//       //       children: [
//       //         { title: t('list'), path: paths.dashboard.order.root },
//       //         { title: t('details'), path: paths.dashboard.order.demo.details },
//       //       ],
//       //     },

//       // // INVOICE
//       // {
//       //   title: t('invoice'),
//       //   path: paths.dashboard.invoice.root,
//       //   icon: ICONS.invoice,
//       //   children: [
//       //     { title: t('list'), path: paths.dashboard.invoice.root },
//       //     { title: t('details'), path: paths.dashboard.invoice.demo.details },
//       //     { title: t('create'), path: paths.dashboard.invoice.new },
//       //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
//       //   ],
//       // },

//       //     // BLOG
//       //     {
//       //       title: t('blog'),
//       //       path: paths.dashboard.post.root,
//       //       icon: ICONS.blog,
//       //       children: [
//       //         { title: t('list'), path: paths.dashboard.post.root },
//       //         { title: t('details'), path: paths.dashboard.post.demo.details },
//       //         { title: t('create'), path: paths.dashboard.post.new },
//       //         { title: t('edit'), path: paths.dashboard.post.demo.edit },
//       //       ],
//       //     },

//       //     // JOB
//       //     {
//       //       title: t('job'),
//       //       path: paths.dashboard.job.root,
//       //       icon: ICONS.job,
//       //       children: [
//       //         { title: t('list'), path: paths.dashboard.job.root },
//       //         { title: t('details'), path: paths.dashboard.job.demo.details },
//       //         { title: t('create'), path: paths.dashboard.job.new },
//       //         { title: t('edit'), path: paths.dashboard.job.demo.edit },
//       //       ],
//       //     },

//       //     // TOUR
//       //     {
//       //       title: t('tour'),
//       //       path: paths.dashboard.tour.root,
//       //       icon: ICONS.tour,
//       //       children: [
//       //         { title: t('list'), path: paths.dashboard.tour.root },
//       //         { title: t('details'), path: paths.dashboard.tour.demo.details },
//       //         { title: t('create'), path: paths.dashboard.tour.new },
//       //         { title: t('edit'), path: paths.dashboard.tour.demo.edit },
//       //       ],
//       //     },

//       //     // FILE MANAGER
//       //     {
//       //       title: t('file_manager'),
//       //       path: paths.dashboard.fileManager,
//       //       icon: ICONS.folder,
//       //     },

//       //     // MAIL
//       //     {
//       //       title: t('mail'),
//       //       path: paths.dashboard.mail,
//       //       icon: ICONS.mail,
//       //       info: <Label color="error">+32</Label>,
//       //     },

//       //     // CHAT
//       //     {
//       //       title: t('chat'),
//       //       path: paths.dashboard.chat,
//       //       icon: ICONS.chat,
//       //     },

//       //     // CALENDAR
//       //     {
//       //       title: t('calendar'),
//       //       path: paths.dashboard.calendar,
//       //       icon: ICONS.calendar,
//       //     },

//       //     // KANBAN
//       //     {
//       //       title: t('kanban'),
//       //       path: paths.dashboard.kanban,
//       //       icon: ICONS.kanban,
//       //     },
//       //   ],
//       // },

//       // DEMO MENU STATES

//       {
//         subheader: t(t('Upcoming')),
//         items: [
//           // {
//           //   // default roles : All roles can see this entry.
//           //   // roles: ['user'] Only users can see this item.
//           //   // roles: ['admin'] Only admin can see this item.
//           //   // roles: ['admin', 'manager'] Only admin/manager can see this item.
//           //   // Reference from 'src/guards/RoleBasedGuard'.
//           //   title: t('item_by_roles'),
//           //   path: paths.dashboard.permission,
//           //   icon: ICONS.lock,
//           //   roles: ['admin', 'manager'],
//           //   caption: t('only_admin_can_see_this_item'),
//           // },
//           // {
//           //   title: t('menu_level'),
//           //   path: '#/dashboard/menu_level',
//           //   icon: ICONS.menuItem,
//           //   children: [
//           //     {
//           //       title: t('menu_level_1a'),
//           //       path: '#/dashboard/menu_level/menu_level_1a',
//           //     },
//           //     {
//           //       title: t('menu_level_1b'),
//           //       path: '#/dashboard/menu_level/menu_level_1b',
//           //       children: [
//           //         {
//           //           title: t('menu_level_2a'),
//           //           path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
//           //         },
//           //         {
//           //           title: t('menu_level_2b'),
//           //           path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
//           //           children: [
//           //             {
//           //               title: t('menu_level_3a'),
//           //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
//           //             },
//           //             {
//           //               title: t('menu_level_3b'),
//           //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
//           //             },
//           //           ],
//           //         },
//           //       ],
//           //     },
//           //   ],
//           // },
//           // {
//           //   title: t('item_disabled'),
//           //   path: '#disabled',
//           //   icon: ICONS.disabled,
//           //   disabled: true,
//           // },
//           // {
//           //   title: t('item_label'),
//           //   path: '#label',
//           //   icon: ICONS.label,
//           //   info: (
//           //     <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
//           //       NEW
//           //     </Label>
//           //   ),
//           // },
//           // {
//           //   title: t('item_caption'),
//           //   path: '#caption',
//           //   icon: ICONS.menuItem,
//           //   caption:
//           //     'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
//           // },
//           // {
//           //   title: t('item_external_link'),
//           //   path: 'https://www.google.com/',
//           //   icon: ICONS.external,
//           // },
//           // {
//           //   title: t('blank'),
//           //   path: paths.dashboard.blank,
//           //   icon: ICONS.blank,
//           // },
//           // {
//           //   title: t('sampleDashboard'),
//           //   // path: paths.dashboard.sampleDashboard,
//           //   icon: ICONS.blank,
//           // },

//           {
//             title: t('blank'),
//             path: paths.dashboard.blank,
//             icon: ICONS.blank,
//           },
//           // {
//           //   title: t('pos checkin and checkout'),
//           //   path: paths.dashboard.posCheckingCheckout,
//           //   // icon: ICONS.blank,
//           // },
//         ],
//       },
//     ],
//     [t, userRole]
//   );

//   return data;
// }


// all new
import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// redux
import { useSelector } from 'src/redux/store';


// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  setting: icon('ic_setting'),
  admin: icon('ic_admin'),
  newinvoice: icon('ic_invoice-add'),
  grnBill: icon('ic_bill-check'),
  purchaseOrder: icon('ic_purchase'),
  price: icon('ic_price'),
  stock: icon('ic_stock'),
  credit: icon('ic_credit'),
  receable: icon('ic_receive'),
  payable: icon('ic_payable'),
  cheque: icon('ic_cheque'),
  cash: icon('ic_cash'),
  report: icon('ic_report'),
  reportbar: icon('ic_reportbar'),
  configuration: icon('ic_configuration'),
  setups: icon('ic_setup'),
  pay: icon('ic_pay'),
  bill: icon('ic_bill'),
  customerInvoice: icon('ic_customerInvoice'),
  salereceipts: icon('ic_salereceipts'),
  manufacturing: icon('ic_manufact'),
  bank: icon('ic_bank'),
  money: icon('ic_money'),
  bankReceipt: icon('ic_bankReceipts'),
  stockAdjustment: icon('ic_stockAdjustmet'),
  stockReplenishment: icon('ic_stockReplenishment'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const userRole = useSelector((state) => state.auth.user.roles[0]);
  console.log("FIREUSER", userRole);
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_STORES_INCHARGE" ? t('ERP') : null,
        // items: [
        //   // { title: t('dashboard'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },
        //   { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
        //   // { title: t('analytics'), path: paths.dashboard.general.analytics, icon: ICONS.analytics },
        //   // { title: t('banking'), path: paths.dashboard.general.banking, icon: ICONS.banking },
        //   // { title: t('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },
        //   // { title: t('file'), path: paths.dashboard.general.file, icon: ICONS.file },
        // ],


        items: [
          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_STORES_INCHARGE" ? { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard } : null
        ].filter(Boolean),
      },

      // {
      //   subheader: t('ERP'),
      //   items: [
      //     {
      //       title: t('Material Master'),
      //       path: paths.dashboard.general.ecommerce,
      //       icon: ICONS.setting,
      //       children: [
      //         { title: t('Master Data'), path: paths.dashboard.materialmaster.new },
      //         { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
      //       ],
      //     },
      //   ],
      // },

      // ----------------------------------------------------------------------

      // {
      //   subheader: t('Point of Sales'),
      //   items: [
      //     {
      //       title: t('Point of Sales'),
      //       path: paths.dashboard.general.ecommerce,
      //       icon: ICONS.ecommerce,
      //       children: [
      //         { title: t('dashboard'), path: paths.dashboard.general.pos },
      //         { title: t('Add Product'), path: paths.dashboard.product.root },
      //         { title: t('Checkout'), path: paths.dashboard.general.analytics },
      //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
      //         { title: t('cards'), path: paths.dashboard.user.cards },
      //         { title: t('account'), path: paths.dashboard.user.account },
      //       ],
      //     },
      //   ],
      // },

      // {
      //   items: [{ title: t('POS'), path: paths.dashboard.general.pos, icon: ICONS.ecommerce }],
      // },

      // POS
      {
        subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('pos') : null,
        items: [
          userRole === "ROLE_ADMIN" || userRole === "ROLE_POS" ? {
            title: t('POS'),
            path: paths.dashboard.possecondview,
            icon: ICONS.ecommerce,
            // children: [
            //   // { title: t('POS Main View'), path: paths.dashboard.posmainview },
            //   { title: t('POS Second View'), path: paths.dashboard.possecondview },
            //   // { title: t('POS Third View'), path: paths.dashboard.posthirdview },
            //   // { title: t('POS Fourth View'), path: paths.dashboard.posfourthview },
            // ],
          } : null,
          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_POS" ? {

            title: t('Measurement'),
            path: paths.dashboard.measurement.root,
            icon: ICONS.ecommerce,
            children: [
              userRole === "ROLE_ADMIN" || userRole === "ROLE_POS" || userRole === "ROLE_MODERATOR" ? { title: t('Create Measurement'), path: paths.dashboard.measurement.new } : null,
              userRole === "ROLE_ADMIN" || userRole === "ROLE_POS" || userRole === "ROLE_MODERATOR" ? { title: t('Measurement List'), path: paths.dashboard.measurement.root } : null,
              // { title: t('POS Third View'), path: paths.dashboard.posthirdview },
              // { title: t('POS Fourth View'), path: paths.dashboard.posfourthview },
            ].filter(item => item !== null),
          } : null,

          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_STORES_INCHARGE" ? {
            title: t('Stock Issuing'),
            path: paths.dashboard.stockissuing.root,
            icon: ICONS.ecommerce,
            children: [
              // { title: t('Create Stock Issuing'), path: paths.dashboard.stockissuing.new },
              { title: t('Stock Issues - Pending'), path: paths.dashboard.stockissuing.root },
              { title: t('Stock Issued List'), path: paths.dashboard.stockissuing.issuedList },
              // { title: t('POS Third View'), path: paths.dashboard.posthirdview },
              // { title: t('POS Fourth View'), path: paths.dashboard.posfourthview },
            ],
          } : null,
        ].filter(item => item !== null),
      },

      // POS REPORT
      {
        subheader: t('pos report'),
        items: [
          // {
          //   title: t('POS'),
          //   path: paths.dashboard.sampleDashboard,
          //   icon: ICONS.ecommerce,
          // },
          {
            title: t('Receivable'),
            path: paths.dashboard.posReceivable.root,
            icon: ICONS.ecommerce,
            children: [
              { title: t('POS receivable'), path: paths.dashboard.posReceivable.root },
              { title: t('POS Received List'), path: paths.dashboard.cashReceivable.receivedList },
            ],
          },
          {
            title: t('sales History'),
            path: paths.dashboard.pos.history,
            icon: ICONS.ecommerce,
          },

          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_FIN_ASSISTANT" ?
            {
              title: t('sales Discount History'),
              path: paths.dashboard.pos.discountHistory,
              icon: ICONS.ecommerce,
            } : null,

          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_POS" || userRole === "ROLE_FIN_ASSISTANT" ?
            {
              title: t('Cash Collection report'),
              path: paths.dashboard.sales.root,
              icon: ICONS.ecommerce,
            } : null,
          // {
          //   title: t('Trial Confirmation'),
          //   path: paths.dashboard.trialConfirmation.root,
          //   icon: ICONS.ecommerce,
          // },

          {
            title: t('Trial Confirmation'),
            path: paths.dashboard.trialConfirmation.confirmedlist,
            icon: ICONS.ecommerce,
            children: [
              { title: t('Trial Confirmation'), path: paths.dashboard.trialConfirmation.root },
              { title: t('Trial Confirmed List'), path: paths.dashboard.trialConfirmation.confirmedlist },
            ],
          },

          // {
          //   title: t('stock Transfer list'),
          //   path: paths.dashboard.stockTransferAccept.root,
          //   icon: ICONS.stock,
          // },
          // {
          //   title: t('return bill History'),
          //   path: paths.dashboard.pos.return,
          //   icon: ICONS.ecommerce,
          // },
          // {
          //   title: t('sampleDashboard'),
          //   path: paths.dashboard.pos.erpUser,
          //   icon: ICONS.ecommerce,
          // },
        ].filter(item => item !== null),
      },

      // ----------------------------------------------------------------------

      // Replenishment
      {
        subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ? t('Replenishment') : null,
        items: [
          userRole === "ROLE_ADMIN" ? {
            // VENDOR
            title: t('Business Partners'),
            path: paths.dashboard.vendor.root,
            icon: ICONS.user,
            children: [
              { title: t('Business Partners creation '), path: paths.dashboard.vendor.new },
              { title: t('Business Partners list'), path: paths.dashboard.vendor.list },
              // { title: t('vendor profile'), path: paths.dashboard.vendor.root },
              // { title: t('edit vendor'), path: paths.dashboard.vendor.demo.edit },
              // { title: t('vendor account'), path: paths.dashboard.vendor.account },
              // { title: t('vendor profile'), path: paths.dashboard.vendor.root },
              // { title: t('cards'), path: paths.dashboard.vendor.cards },
            ],
          } : null,
          // PO
          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_STORES_INCHARGE" || userRole === "ROLE_FIN_ASSISTANT" ? {
            title: t('purchase order'),
            path: paths.dashboard.po.root,
            icon: ICONS.purchaseOrder,
            children: [
              { title: t('PO creation'), path: paths.dashboard.po.new },
              { title: t('PO list'), path: paths.dashboard.po.root },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          } : null,
          // GRN
          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_STORES_INCHARGE" || userRole === "ROLE_FIN_ASSISTANT" ? {
            title: t('GRN'),
            path: paths.dashboard.grn.root,
            icon: ICONS.grnBill,
            children: [
              userRole === "ROLE_ADMIN" || "ROLE_ADMIN" || "ROLE_STORES_INCHARGE" || userRole === "ROLE_FIN_ASSISTANT" ? { title: t('grn creation'), path: paths.dashboard.grn.new } : null,
              userRole === "ROLE_MODERATOR" || "ROLE_ADMIN" || "ROLE_STORES_INCHARGE" || userRole === "ROLE_FIN_ASSISTANT" ? { title: t('grn list'), path: paths.dashboard.grn.root } : null,
              // { title: t('direct grn creation'), path: paths.dashboard.grn.directgrn },
              // { title: t('edit'), path: paths.dashboard.grn.demo.edit },
              // { title: t('details'), path: paths.dashboard.grn.demo.details },
            ].filter(item => item !== null),
          } : null,

          // INVOICE
          // {
          //   title: t('Invoice'),
          //   path: paths.dashboard.erpinvoice.root,
          //   icon: ICONS.newinvoice,
          //   children: [
          //     { title: t('invoice creation'), path: paths.dashboard.erpinvoice.new },
          //     { title: t('invoice list'), path: paths.dashboard.erpinvoice.root },
          //     // { title: t('details'), path: paths.dashboard.erpinvoice.demo.details },

          //     // { title: t('edit'), path: paths.dashboard.erpinvoice.demo.edit },
          //   ],
          // },

          // Payments
          userRole === "ROLE_ADMIN" || userRole === "ROLE_FIN_ASSISTANT" ? {
            title: t('Petty Cash'),
            path: paths.dashboard.cashandbank.root,
            icon: ICONS.pay,
            children: [
              { title: t('Petty Cash Payment'), path: paths.dashboard.cashandbank.new },
              { title: t('Petty Cash Payment list'), path: paths.dashboard.cashandbank.root },
              // { title: t('Petty Cash Reimbursement'), path: paths.dashboard.cashandbank.reimbursement },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          } : null,
          userRole === "ROLE_ADMIN" || userRole === "ROLE_FIN_ASSISTANT" ? {
            title: t('Bank Payment'),
            path: paths.dashboard.cashandbankpayment.root,
            icon: ICONS.bank,
            children: [
              { title: t('Payment Voucher'), path: paths.dashboard.cashandbankpayment.new },
              { title: t('Payment Voucher list'), path: paths.dashboard.cashandbankpayment.root },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          } : null,
          userRole === "ROLE_ADMIN" || userRole === "ROLE_FIN_ASSISTANT" ? {
            title: t('Cash Receipts'),
            path: paths.dashboard.receipts.root,
            icon: ICONS.money,
            children: [
              { title: t('Cash Receipts'), path: paths.dashboard.receipts.new },
              { title: t('Cash Receipts list'), path: paths.dashboard.receipts.root },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          } : null,
          userRole === "ROLE_ADMIN" || userRole === "ROLE_FIN_ASSISTANT" ? {
            title: t('Bank Receipts'),
            path: paths.dashboard.bankReceipts.root,
            icon: ICONS.bankReceipt,
            children: [
              { title: t('Bank Receipts'), path: paths.dashboard.bankReceipts.new },
              { title: t('Bank Receipts list'), path: paths.dashboard.bankReceipts.root },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          } : null,

          // {
          //   title: t('cash & bank'),
          //   path: paths.dashboard.cashandbank.new,
          //   icon: ICONS.pay,
          //   children: [
          //     {
          //       title: t('Petty Cash'),
          //       path:  paths.dashboard.cashandbank.new,
          //       children: [
          //         {
          //           title: t('Petty Cash Payment'),
          //           path: paths.dashboard.cashandbank.new,
          //         },
          //         {
          //           title: t('Petty Cash Payment list'),
          //           path: paths.dashboard.cashandbank.root,
          //         },
          //       ],
          //     },
          //     {
          //       title: t('Cash & Bank Payment'),
          //       path: paths.dashboard.cashandbank.new,
          //       children: [
          //         {
          //           title: t('Cash & Bank Payment'),
          //           path: paths.dashboard.cashandbank.new,
          //         },
          //         {
          //           title: t('Cash & Bank Payment List'),
          //           path: paths.dashboard.cashandbank.root,
          //         },
          //       ],
          //     },
          //   ],
          // },

          // Bill
          // {
          //   title: t('Vendor bill'),
          //   path: paths.dashboard.bill.root,
          //   icon: ICONS.bill,
          //   children: [
          //     { title: t('bill Creation'), path: paths.dashboard.bill.new },
          //     { title: t('bill list'), path: paths.dashboard.bill.root },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
          //   ],
          // },

          // Out Going Payment
          // {
          //   title: t('out Going Payment'),
          //   path: paths.dashboard.outGoingpayment.root,
          //   icon: ICONS.purchaseOrder,
          //   children: [
          //     // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
          //     { title: t('out going payment'), path: paths.dashboard.outGoingpayment.root },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
          //   ],
          // },
        ].filter(item => item !== null),
      },

      // SD Module
      // {
      //   subheader: 'Sales & Distribution',
      //   items: [
      //     // {
      //     //   title: t('report'),
      //     //   path: paths.dashboard.report.root,
      //     //   icon: ICONS.invoice,
      //     //   children: [
      //     //     { title: t('list'), path: paths.dashboard.report.root },
      //     //     { title: t('newlist'), path: paths.dashboard.report.newlist },
      //     //     { title: t('details'), path: paths.dashboard.report.demo.details },
      //     //     { title: t('create'), path: paths.dashboard.report.new },
      //     //     { title: t('edit'), path: paths.dashboard.report.demo.edit },
      //     //   ],
      //     // },
      //     // Sales Order
      //     {
      //       title: t('sales order'),
      //       path: paths.dashboard.salesOrder.root,
      //       icon: ICONS.purchaseOrder,
      //       children: [
      //         { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
      //         { title: t('sales order list'), path: paths.dashboard.salesOrder.root },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //       ],
      //     },

      //     // Out Bound Delivery
      //     {
      //       title: t('out Bound Delivery'),
      //       path: paths.dashboard.outBoundDelivery.root,
      //       icon: ICONS.purchaseOrder,
      //       children: [
      //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
      //         { title: t('out bound delivery'), path: paths.dashboard.outBoundDelivery.root },
      //         {
      //           title: t('out bound delivery list'),
      //           path: paths.dashboard.outBoundDelivery.outBoundlist,
      //         },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //       ],
      //     },
      //     // Picking List
      //     {
      //       title: t('picking list'),
      //       path: paths.dashboard.pickingList.root,
      //       icon: ICONS.purchaseOrder,
      //       children: [
      //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
      //         { title: t('picking list'), path: paths.dashboard.pickingList.root },
      //         { title: t('all picking list'), path: paths.dashboard.pickingList.pickingList },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //       ],
      //     },
      //     // Delivery
      //     {
      //       title: t('order delivery'),
      //       path: paths.dashboard.delivery.root,
      //       icon: ICONS.purchaseOrder,
      //       children: [
      //         // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
      //         { title: t('delivery list'), path: paths.dashboard.delivery.root },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //       ],
      //     },
      //     // SALE RECEIPTS
      //     {
      //       title: t('sale receipts'),
      //       path: paths.dashboard.cashSale.root,
      //       icon: ICONS.salereceipts,
      //       children: [
      //         { title: t('enter sale receipts'), path: paths.dashboard.cashSale.new },
      //         { title: t('sale receipt list'), path: paths.dashboard.cashSale.root },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //       ],
      //     },
      //     // CUSTOMER INVOICE
      //     {
      //       title: t('create invoices'),
      //       path: paths.dashboard.creditSale.root,
      //       icon: ICONS.customerInvoice,
      //       children: [
      //         { title: t('customer invoice'), path: paths.dashboard.creditSale.new },
      //         { title: t('customer invoice list'), path: paths.dashboard.creditSale.root },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('details'), path: paths.dashboard.po.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //       ],
      //     },
      //     // In Coming Payment
      //     // {
      //     //   title: t('in coming payment'),
      //     //   path: paths.dashboard.inComingpayment.root,
      //     //   icon: ICONS.purchaseOrder,
      //     //   children: [
      //     //     // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
      //     //     { title: t('in coming payment'), path: paths.dashboard.inComingpayment.root },
      //     //     // { title: t('details'), path: paths.dashboard.po.demo.details },
      //     //     // { title: t('details'), path: paths.dashboard.po.demo.details },
      //     //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
      //     //   ],
      //     // },
      //   ],
      // },

      // // Pricing Module
      // {
      //   subheader: t('Pricing Module'),
      //   items: [
      //     {
      //       title: t('Create price'),
      //       path: paths.dashboard.price.root,
      //       icon: ICONS.price,
      //       children: [
      //         { title: t('create price'), path: paths.dashboard.price.new },
      //         // { title: t('Price Assign & List'), path: paths.dashboard.price.root },
      //         // { title: t('Whole Sale Price & List'), path: paths.dashboard.price.assignPrice },

      //         // { title: t('details'), path: paths.dashboard.price.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.price.demo.edit },
      //       ],
      //     },
      //     {
      //       title: t('Price Assign'),
      //       path: paths.dashboard.price.root,
      //       icon: ICONS.price,
      //       children: [
      //         { title: t('Price Assign'), path: paths.dashboard.price.root },
      //         { title: t('Price Assign List'), path: paths.dashboard.price.assignPriceList },
      //         // { title: t('Whole Sale Price & List'), path: paths.dashboard.price.assignPrice },
      //         // { title: t('details'), path: paths.dashboard.price.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.price.demo.edit },
      //       ],
      //     },
      //     {
      //       title: t('Customer Assign'),
      //       path: paths.dashboard.price.root,
      //       icon: ICONS.price,
      //       children: [
      //         // { title: t('create price'), path: paths.dashboard.price.new },
      //         // { title: t('Price Assign & List'), path: paths.dashboard.price.root },
      //         { title: t('Customer Assign'), path: paths.dashboard.price.assignPrice },
      //         // { title: t('details'), path: paths.dashboard.price.demo.details },
      //         // { title: t('edit'), path: paths.dashboard.price.demo.edit },
      //       ],
      //     },
      //   ],
      // },

      // FInance Module
      {
        subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_FIN_ASSISTANT" ? t('Finance Module') : null,
        items: userRole === "ROLE_ADMIN" || userRole === "ROLE_FIN_ASSISTANT" ? [
          // {
          //   title: t('credit Management'),
          //   path: paths.dashboard.creditMan.root,
          //   icon: ICONS.credit,
          //   children: [
          //     { title: t('Define Credit Management'), path: paths.dashboard.creditMan.new },
          //     { title: t('List of Credit'), path: paths.dashboard.creditMan.root },
          //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
          //   ],
          // },
          // {
          //   title: t('account Receivable'),
          //   path: paths.dashboard.accountReceivable.root,
          //   icon: ICONS.receable,
          //   children: [
          //     // { title: t('create'), path: paths.dashboard.creditMan.new },
          //     { title: t('A/R list'), path: paths.dashboard.accountReceivable.root },
          //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
          //   ],
          // },
          // {
          //   title: t('account Payble'),
          //   path: paths.dashboard.accountPayble.root,
          //   icon: ICONS.payable,
          //   children: [
          //     // { title: t('create'), path: paths.dashboard.creditMan.new },
          //     { title: t('A/P list'), path: paths.dashboard.accountPayble.root },
          //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
          //   ],
          // },
          // {
          //   title: t('cheque details'),
          //   path: paths.dashboard.postDatedChequeDetails.root,
          //   icon: ICONS.cheque,
          //   children: [
          //     // { title: t('create'), path: paths.dashboard.creditMan.new },
          //     {
          //       title: t('Post Dated Receivable'),
          //       path: paths.dashboard.postDatedChequeDetails.root,
          //     },
          //     { title: t('Post Dated Payble'), path: paths.dashboard.postDatedPayble.root },
          //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
          //   ],
          // },
          // {
          //   title: t('cash details'),
          //   path: paths.dashboard.cashReceivable.root,
          //   icon: ICONS.cash,
          //   children: [
          //     // { title: t('create'), path: paths.dashboard.creditMan.new },
          //     {
          //       title: t('Cash Receivable'),
          //       path: paths.dashboard.cashReceivable.root,
          //     },
          //     { title: t('Cash Payble'), path: paths.dashboard.cashPayble.root },
          //     // { title: t('details'), path: paths.dashboard.creditMan.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.creditMan.demo.edit },
          //   ],
          // },

          // {
          //   title: t('statement report'),
          //   path: paths.dashboard.report.root,
          //   icon: ICONS.report,
          //   children: [
          //     {
          //       title: t('balance sheet'),
          //       path: paths.dashboard.report.balancesheet,
          //     },
          //     {
          //       title: t('profit and loss'),
          //       path: paths.dashboard.report.profitandloss,
          //     },
          //     {
          //       title: t('cash flow statement'),
          //       path: '#/dashboard/menu_level/menu_level_1a',
          //     },
          //     {
          //       title: t('executive summary'),
          //       path: '#/dashboard/menu_level/menu_level_1a',
          //     },
          //     {
          //       title: t('Tax report'),
          //       // path: paths.dashboard.report.newtax,
          //       path: '#/dashboard/menu_level/menu_level_1a',
          //     },
          //   ],
          // },
          {
            title: t('summary report'),
            path: '#/dashboard/menu_level',
            icon: ICONS.reportbar,
            children: [
              // {
              //   title: t('general ledger'),
              //   path: paths.dashboard.chartofaccounts.genaralledger,
              // },
              {
                title: t('trial balance'),
                path: paths.dashboard.chartofaccounts.trialbalance,
              },
              // {
              //   title: t('journal report'),
              //   path: '#/dashboard/menu_level/menu_level_1a',
              // },
            ],
          },
          // {
          //   title: t('configurations'),
          //   path: '#/dashboard/menu_level',
          //   icon: ICONS.configuration,
          //   children: [
          //     {
          //       title: t('module setup'),
          //       path: paths.dashboard.module.new,
          //     },
          //     // {
          //     //   title: t('trial balance'),
          //     //   path: '#/dashboard/menu_level/menu_level_1a',
          //     // },
          //     // {
          //     //   title: t('journal report'),
          //     //   path: '#/dashboard/menu_level/menu_level_1a',
          //     // },
          //   ],
          // },

          {
            title: t('configurations'),
            path: '#/dashboard/menu_level',
            icon: ICONS.setups,
            children: [
              {
                title: t('module setup'),
                path: paths.dashboard.module.new,
              },
              {
                title: t('CA accounts setup'),
                path: '#/dashboard/menu_level/menu_level_1b',
                children: [
                  {
                    title: t('create chart of accounts'),
                    path: paths.dashboard.chartofaccounts.new,
                  },
                  {
                    title: t('chart of accounts'),
                    path: paths.dashboard.chartofaccounts.list,
                  },
                  // {
                  //   title: t('menu_level_2b'),
                  //   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
                  //   children: [
                  //     {
                  //       title: t('menu_level_3a'),
                  //       path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
                  //     },
                  //     {
                  //       title: t('menu_level_3b'),
                  //       path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
                  //     },
                  //   ],
                  // },
                ],
              },
            ],
          },
          {
            title: t('chart of accounts'),
            path: '#/dashboard/menu_level',
            icon: ICONS.configuration,
            children: [
              {
                title: t('create new accounts'),
                path: paths.dashboard.chartofaccounts.createnew,
              },
              {
                title: t('Journal list'),
                path: paths.dashboard.chartofaccounts.accountlist,
              },
              // {
              //   title: t('chart of accounts'),
              //   path: paths.dashboard.chartofaccounts.list,
              // },

              // {
              //   title: t('trial balance'),
              //   path: '#/dashboard/menu_level/menu_level_1a',
              // },
              // {
              //   title: t('journal report'),
              //   path: '#/dashboard/menu_level/menu_level_1a',
              // },
            ],
          },
          // Payments
          // {
          //   title: t('cash & bank'),
          //   path: paths.dashboard.cashandbank.root,
          //   icon: ICONS.pay,
          //   children: [
          //     { title: t('payment Creation'), path: paths.dashboard.cashandbank.new },
          //     { title: t('payment list'), path: paths.dashboard.cashandbank.root },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
          //   ],
          // },
          // // Bill
          // {
          //   title: t('bill'),
          //   path: paths.dashboard.bill.root,
          //   icon: ICONS.bill,
          //   children: [
          //     { title: t('bill Creation'), path: paths.dashboard.bill.new },
          //     { title: t('bill list'), path: paths.dashboard.bill.root },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
          //   ],
          // },
          // {
          //   title: t('Payments'),
          //   path: '#/dashboard/menu_level',
          //   icon: ICONS.pay,
          //   children: [
          //     {
          //       title: t('create payments'),
          //       path: paths.dashboard.payment.new,
          //     },
          //     {
          //       title: t('payment list'),
          //       path: paths.dashboard.payment.list,
          //     },
          //     // {
          //     //   title: t('accounts list'),
          //     //   path: paths.dashboard.chartofaccounts.accountlist,
          //     // },
          //     // {
          //     //   title: t('chart of accounts'),
          //     //   path: paths.dashboard.chartofaccounts.list,
          //     // },
          //     // {
          //     //   title: t('create chart of accounts'),
          //     //   path: paths.dashboard.chartofaccounts.new,
          //     // },
          //     // {
          //     //   title: t('trial balance'),
          //     //   path: '#/dashboard/menu_level/menu_level_1a',
          //     // },
          //     // {
          //     //   title: t('journal report'),
          //     //   path: '#/dashboard/menu_level/menu_level_1a',
          //     // },
          //   ],
          // },
        ] : [],
      },
      {
        subheader: userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_STORES_INCHARGE" ? t('Master Data') : null,
        items: userRole === "ROLE_ADMIN" || userRole === "ROLE_STORES_INCHARGE" ? [
          {
            title: t('Material Master'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.setting,
            children: [
              { title: t('Master Data'), path: paths.dashboard.materialmaster.new },
              { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
              { title: t('Create Product Category'), path: paths.dashboard.category.new },
            ],
          },
        ] : [],
      },
      {
        subheader: userRole === "ROLE_ADMIN" ? t('Price Management') : null,
        items: userRole === "ROLE_ADMIN" ? [
          {
            title: t('Price Management'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.setting,
            children: [
              { title: t('Update New Price'), path: paths.dashboard.priceManagement.new },
              { title: t('Price History List'), path: paths.dashboard.priceManagement.list },
              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
              // // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
              // { title: t('Create Product Category'), path: paths.dashboard.category.new },
            ],
          },

        ] : [],
      },
      {
        subheader: userRole === "ROLE_ADMIN" ? t('Price Management') : null,
        items: userRole === "ROLE_ADMIN" ? [
          {
            title: t('Currency Management'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.setting,
            children: [
              { title: t('Create Currency'), path: paths.dashboard.currencyManagement.new },
              // { title: t('Currency List'), path: paths.dashboard.currencyManagement.list },
              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
              // // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
              // { title: t('Create Product Category'), path: paths.dashboard.category.new },
            ],
          },
        ] : [],
      },
      {
        subheader: userRole === "ROLE_ADMIN" ? t('Manufacturing') : null,
        items: userRole === "ROLE_ADMIN" ? [
          {
            title: t('Manufacturing'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.manufacturing,
            children: [
              { title: t('Production Capacity'), path: paths.dashboard.manufacturing.new },
              { title: t('Define Delivery & Trial Date'), path: paths.dashboard.manufacturing.masterData },
              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
              // { title: t('Create Product Category'), path: paths.dashboard.category.new },
            ],
          },
          // {
          //   title: t('Measurement Print'),
          //   path: paths.dashboard.general.ecommerce,
          //   icon: ICONS.manufacturing,
          //   children: [
          //     { title: t('Print Measurement Sheet'), path: paths.dashboard.measurementview.root },
          //     // { title: t('Manufacturing Master Data'), path: paths.dashboard.manufacturing.masterData },
          //     // { title: t('Master Data List'), path: paths.dashboard.materialmaster.newlist },
          //     // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
          //     // { title: t('Create Product Category'), path: paths.dashboard.category.new },
          //   ],
          // },
        ] : [],
      },
      {
        subheader: t('Stock Transfer'),
        items: [
          {
            title: t('Stock Transfer'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.stock,
            children: [
              { title: t('Multiple Stock Transfer'), path: paths.dashboard.multipleStockTransfer.new },
              { title: t('Stock Transfer List'), path: paths.dashboard.multipleStockTransfer.root },

              // { title: t('Single Stock Transfer'), path: paths.dashboard.internalTransfer.new },

              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
            ].filter(item => item !== null),
          },
          {
            title: t('Stock Replenishment'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.stockReplenishment,
            children: [
              { title: t('Stock Replenishment'), path: paths.dashboard.stockTransferAccept.root },
              // { title: t('Stock Transfer List'), path: paths.dashboard.multipleStockTransfer.root },

              // { title: t('Single Stock Transfer'), path: paths.dashboard.internalTransfer.new },

              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
            ],
          },
        ],
      },
      {
        subheader: userRole === "ROLE_ADMIN" ? t('Stock Adjusment') : null,
        items: userRole === "ROLE_ADMIN" ? [
          {
            title: t('Stock Adjusment'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.stockAdjustment,
            children: [
              { title: t('Stock Adjusment'), path: paths.dashboard.stockAdjustment.new },
              // { title: t('Master Data List'), path: paths.dashboard.materialmaster.root },
            ],
          },
        ] : [],
      },

      // SETTINGS
      {
        subheader: userRole === "ROLE_ADMIN" ? t('Settings') : null,
        items: userRole === "ROLE_ADMIN" ? [
          // {
          //   title: t('System Settings'),
          //   path: paths.dashboard.system.root,
          //   icon: ICONS.setting,
          //   children: [
          //     { title: t('Products Feilds'), path: paths.dashboard.system.new },
          //     // { title: t('Purchase Order'), path: paths.dashboard.system.purchaseorder },
          //     // { title: t('GRN'), path: paths.dashboard.system.grn },
          //     // { title: t('profile'), path: paths.dashboard.system.root },
          //     // { title: t('cards'), path: paths.dashboard.system.cards },
          //     // { title: t('list'), path: paths.dashboard.system.list },
          //     // { title: t('edit'), path: paths.dashboard.system.demo.edit },
          //     // { title: t('account'), path: paths.dashboard.system.account },
          //   ],
          // },

          // // INVOICE
          // {
          //   title: t('invoice'),
          //   path: paths.dashboard.invoice.root,
          //   icon: ICONS.invoice,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.invoice.root },
          //     { title: t('details'), path: paths.dashboard.invoice.demo.details },
          //     { title: t('create'), path: paths.dashboard.invoice.new },
          //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
          //   ],
          // },

          // System Master Data
          // {
          //   title: t('System Master'),
          //   path: paths.dashboard.general.ecommerce,
          //   icon: ICONS.setting,
          //   children: [
          //     { title: t('System Definition'), path: paths.dashboard.systremmaster.definition },
          //   ],
          // },
          // System Master
          {
            title: t('System Master'),
            path: paths.dashboard.systemMasterData.root,
            icon: ICONS.setting,
            children: [
              { title: t('system'), path: paths.dashboard.systemMasterData.new },
              // { title: t('Measurements Master'), path: paths.dashboard.systemMasterData.masterDataMaster },
              // { title: t('master data'), path: paths.dashboard.systemMasterData.masterData },
              { title: t('system location'), path: paths.dashboard.systemMasterData.adminUser },
              { title: t('UOM'), path: paths.dashboard.systemMasterData.uom },
              { title: t('Honorific Names'), path: paths.dashboard.systemMasterData.personalDefinition },
              { title: t('Cost centers'), path: paths.dashboard.systemMasterData.costCenter },
              { title: t('Sub Accounts Type'), path: paths.dashboard.systemMasterData.subAccounts },
              { title: t('Sub Accounts'), path: paths.dashboard.systemMasterData.subAcc },

              // { title: t('Petty cash float'), path: paths.dashboard.systemMasterData.float },

              // { title: t('details'), path: paths.dashboard.systemMasterData.demo.details },
              // { title: t('list'), path: paths.dashboard.systemMasterData.root },
              // { title: t('edit'), path: paths.dashboard.systemMasterData.demo.edit },
            ],
          },
          // Measurements
          {
            title: t('Measurements Master'),
            path: paths.dashboard.measurementMaster.root,
            icon: ICONS.setting,
            children: [
              { title: t('Style'), path: paths.dashboard.measurementMaster.style },
              { title: t('Design Number'), path: paths.dashboard.measurementMaster.designNumber },
              { title: t('Relation'), path: paths.dashboard.measurementMaster.relation },
              { title: t('Fit'), path: paths.dashboard.measurementMaster.fit },
              { title: t('S-SEAM'), path: paths.dashboard.measurementMaster.sSeam },
              { title: t('Funda Length'), path: paths.dashboard.measurementMaster.fundaLength },
              { title: t('Button Type'), path: paths.dashboard.measurementMaster.noOfPkt },
              { title: t('Loop'), path: paths.dashboard.measurementMaster.loop },
              { title: t('Back'), path: paths.dashboard.measurementMaster.back },
              { title: t('Neck Style'), path: paths.dashboard.measurementMaster.neckstyle },
              { title: t('Half'), path: paths.dashboard.measurementMaster.half },
              { title: t('SH Cut'), path: paths.dashboard.measurementMaster.shCut },
              { title: t('Pocket Type'), path: paths.dashboard.measurementMaster.pocketType },
              { title: t('Funda'), path: paths.dashboard.measurementMaster.funda },
              { title: t('D/F Type'), path: paths.dashboard.measurementMaster.dfType },
              { title: t('SH Type'), path: paths.dashboard.measurementMaster.shType },
              { title: t('Kally'), path: paths.dashboard.measurementMaster.kally },
              { title: t('Front'), path: paths.dashboard.measurementMaster.front },
              { title: t('Neck'), path: paths.dashboard.measurementMaster.neck },
              { title: t('Sleeve'), path: paths.dashboard.measurementMaster.sleeve },
              { title: t('Dariz Type'), path: paths.dashboard.measurementMaster.darizType },
              // { title: t('edit'), path: paths.dashboard.adminUser.demo.edit },
              // { title: t('profile'), path: paths.dashboard.adminUser.root },
              // { title: t('cards'), path: paths.dashboard.adminUser.cards },
              // { title: t('account'), path: paths.dashboard.user.account },
            ],
          },
          // USER
          {
            title: t('system users'),
            path: paths.dashboard.adminUser.root,
            icon: ICONS.admin,
            children: [
              { title: t('New System User'), path: paths.dashboard.adminUser.new },
              { title: t('System User List'), path: paths.dashboard.adminUser.list },
              // { title: t('edit'), path: paths.dashboard.adminUser.demo.edit },
              // { title: t('profile'), path: paths.dashboard.adminUser.root },
              // { title: t('cards'), path: paths.dashboard.adminUser.cards },
              // { title: t('account'), path: paths.dashboard.user.account },
            ],
          },
        ] : [],
      },

      // Report
      {
        subheader: 'Report',
        items: [
          // {
          //   title: t('report'),
          //   path: paths.dashboard.report.root,
          //   icon: ICONS.invoice,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.report.root },
          //     { title: t('newlist'), path: paths.dashboard.report.newlist },
          //     { title: t('details'), path: paths.dashboard.report.demo.details },
          //     { title: t('create'), path: paths.dashboard.report.new },
          //     { title: t('edit'), path: paths.dashboard.report.demo.edit },
          //   ],
          // },
          // Stock Movement
          {
            title: t('stock Movement'),
            path: paths.dashboard.stockMovement.root,
            icon: ICONS.purchaseOrder,
            children: [
              // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
              { title: t('Stock report'), path: paths.dashboard.stockReport.root },
              // { title: t('Stock movement report'), path: paths.dashboard.stockMovement.root },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          },
          // Stock Report
          {
            title: t('stock report'),
            path: paths.dashboard.stockMovement.root,
            icon: ICONS.purchaseOrder,
            children: [
              // { title: t('sales order creation'), path: paths.dashboard.salesOrder.new },
              { title: t('Location wise'), path: paths.dashboard.report.locationwisestockReport },
              { title: t('Group wise'), path: paths.dashboard.report.itemGroupwisestockReport },
              // { title: t('Details Stock'), path: paths.dashboard.report.detailsStockReport },
              { title: t('Location Wise Details Stock'), path: paths.dashboard.report.locationdetailsStockReport },

              // { title: t('Details Stock Summery'), path: paths.dashboard.report.locationdetailsStockSummary },

              // { title: t('Stock movement report'), path: paths.dashboard.stockMovement.root },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('details'), path: paths.dashboard.po.demo.details },
              // { title: t('edit'), path: paths.dashboard.po.demo.edit },
            ],
          },
          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" || userRole === "ROLE_FIN_ASSISTANT" ?
            {
              title: t('Sales Report'),
              path: paths.dashboard.report.itemwise,
              icon: ICONS.purchaseOrder,
              children: [
                { title: t('Item Wise'), path: paths.dashboard.report.itemwise },
                { title: t('Item Group Wise'), path: paths.dashboard.report.list },
                { title: t('Customer Wise'), path: paths.dashboard.report.customerwise },
                { title: t('Sales Summary - Branch'), path: paths.dashboard.report.salesSummary },
                { title: t('Details Sales Summery'), path: paths.dashboard.report.locationdetailsSalesSummary },

                // { title: t('Stock movement report'), path: paths.dashboard.stockMovement.root },
                // { title: t('details'), path: paths.dashboard.po.demo.details },
                // { title: t('details'), path: paths.dashboard.po.demo.details },
                // { title: t('edit'), path: paths.dashboard.po.demo.edit },
              ],
            } : null,
          userRole === "ROLE_ADMIN" || userRole === "ROLE_MODERATOR" ?
            {
              title: t('Collection Report'),
              path: paths.dashboard.stockMovement.root,
              icon: ICONS.purchaseOrder,
              children: [
                { title: t('Daily Collection'), path: paths.dashboard.report.collection },
                // { title: t('Stock movement report'), path: paths.dashboard.stockMovement.root },
                // { title: t('details'), path: paths.dashboard.po.demo.details },
                // { title: t('details'), path: paths.dashboard.po.demo.details },
                // { title: t('edit'), path: paths.dashboard.po.demo.edit },
              ],
            } : null,
          // {
          //   title: t('Stock Report'),
          //   path: paths.dashboard.stockMovement.root,
          //   icon: ICONS.purchaseOrder,
          //   children: [
          //     { title: t('Item Wise'), path: paths.dashboard.report.itemwise },
          //     { title: t('Item Group Wise'), path: paths.dashboard.report.list },
          //     { title: t('Customer Wise'), path: paths.dashboard.report.customerwise },

          //     // { title: t('Stock movement report'), path: paths.dashboard.stockMovement.root },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('details'), path: paths.dashboard.po.demo.details },
          //     // { title: t('edit'), path: paths.dashboard.po.demo.edit },
          //   ],
          // },
        ].filter(item => item !== null),
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: t('management'),
      //   items: [
      //     // USER
      //     {
      //       title: t('user'),
      //       path: paths.dashboard.user.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: t('profile'), path: paths.dashboard.user.root },
      //         { title: t('cards'), path: paths.dashboard.user.cards },
      //         { title: t('list'), path: paths.dashboard.user.list },
      //         { title: t('create'), path: paths.dashboard.user.new },
      //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
      //         { title: t('account'), path: paths.dashboard.user.account },
      //       ],
      //     },

      //     // PRODUCT
      //     {
      //       title: t('product'),
      //       path: paths.dashboard.product.root,
      //       icon: ICONS.product,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.product.root },
      //         { title: t('details'), path: paths.dashboard.product.demo.details },
      //         { title: t('create'), path: paths.dashboard.product.new },
      //         { title: t('edit'), path: paths.dashboard.product.demo.edit },
      //       ],
      //     },

      //     // Material Master
      //     {
      //       title: t('materialmaster'),
      //       path: paths.dashboard.materialmaster.root,
      //       icon: ICONS.product,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.materialmaster.root },
      //         { title: t('details'), path: paths.dashboard.materialmaster.demo.details },
      //         { title: t('create'), path: paths.dashboard.materialmaster.new },
      //         { title: t('edit'), path: paths.dashboard.materialmaster.demo.edit },
      //       ],
      //     },

      //     // ORDER
      //     {
      //       title: t('order'),
      //       path: paths.dashboard.order.root,
      //       icon: ICONS.order,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.order.root },
      //         { title: t('details'), path: paths.dashboard.order.demo.details },
      //       ],
      //     },

      // // INVOICE
      // {
      //   title: t('invoice'),
      //   path: paths.dashboard.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: t('list'), path: paths.dashboard.invoice.root },
      //     { title: t('details'), path: paths.dashboard.invoice.demo.details },
      //     { title: t('create'), path: paths.dashboard.invoice.new },
      //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
      //   ],
      // },

      //     // BLOG
      //     {
      //       title: t('blog'),
      //       path: paths.dashboard.post.root,
      //       icon: ICONS.blog,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.post.root },
      //         { title: t('details'), path: paths.dashboard.post.demo.details },
      //         { title: t('create'), path: paths.dashboard.post.new },
      //         { title: t('edit'), path: paths.dashboard.post.demo.edit },
      //       ],
      //     },

      //     // JOB
      //     {
      //       title: t('job'),
      //       path: paths.dashboard.job.root,
      //       icon: ICONS.job,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.job.root },
      //         { title: t('details'), path: paths.dashboard.job.demo.details },
      //         { title: t('create'), path: paths.dashboard.job.new },
      //         { title: t('edit'), path: paths.dashboard.job.demo.edit },
      //       ],
      //     },

      //     // TOUR
      //     {
      //       title: t('tour'),
      //       path: paths.dashboard.tour.root,
      //       icon: ICONS.tour,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.tour.root },
      //         { title: t('details'), path: paths.dashboard.tour.demo.details },
      //         { title: t('create'), path: paths.dashboard.tour.new },
      //         { title: t('edit'), path: paths.dashboard.tour.demo.edit },
      //       ],
      //     },

      //     // FILE MANAGER
      //     {
      //       title: t('file_manager'),
      //       path: paths.dashboard.fileManager,
      //       icon: ICONS.folder,
      //     },

      //     // MAIL
      //     {
      //       title: t('mail'),
      //       path: paths.dashboard.mail,
      //       icon: ICONS.mail,
      //       info: <Label color="error">+32</Label>,
      //     },

      //     // CHAT
      //     {
      //       title: t('chat'),
      //       path: paths.dashboard.chat,
      //       icon: ICONS.chat,
      //     },

      //     // CALENDAR
      //     {
      //       title: t('calendar'),
      //       path: paths.dashboard.calendar,
      //       icon: ICONS.calendar,
      //     },

      //     // KANBAN
      //     {
      //       title: t('kanban'),
      //       path: paths.dashboard.kanban,
      //       icon: ICONS.kanban,
      //     },
      //   ],
      // },

      // DEMO MENU STATES

      {
        subheader: t(t('Upcoming')),
        items: [
          // {
          //   // default roles : All roles can see this entry.
          //   // roles: ['user'] Only users can see this item.
          //   // roles: ['admin'] Only admin can see this item.
          //   // roles: ['admin', 'manager'] Only admin/manager can see this item.
          //   // Reference from 'src/guards/RoleBasedGuard'.
          //   title: t('item_by_roles'),
          //   path: paths.dashboard.permission,
          //   icon: ICONS.lock,
          //   roles: ['admin', 'manager'],
          //   caption: t('only_admin_can_see_this_item'),
          // },
          // {
          //   title: t('menu_level'),
          //   path: '#/dashboard/menu_level',
          //   icon: ICONS.menuItem,
          //   children: [
          //     {
          //       title: t('menu_level_1a'),
          //       path: '#/dashboard/menu_level/menu_level_1a',
          //     },
          //     {
          //       title: t('menu_level_1b'),
          //       path: '#/dashboard/menu_level/menu_level_1b',
          //       children: [
          //         {
          //           title: t('menu_level_2a'),
          //           path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
          //         },
          //         {
          //           title: t('menu_level_2b'),
          //           path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
          //           children: [
          //             {
          //               title: t('menu_level_3a'),
          //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
          //             },
          //             {
          //               title: t('menu_level_3b'),
          //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
          //             },
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   title: t('item_disabled'),
          //   path: '#disabled',
          //   icon: ICONS.disabled,
          //   disabled: true,
          // },
          // {
          //   title: t('item_label'),
          //   path: '#label',
          //   icon: ICONS.label,
          //   info: (
          //     <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
          //       NEW
          //     </Label>
          //   ),
          // },
          // {
          //   title: t('item_caption'),
          //   path: '#caption',
          //   icon: ICONS.menuItem,
          //   caption:
          //     'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
          // },
          // {
          //   title: t('item_external_link'),
          //   path: 'https://www.google.com/',
          //   icon: ICONS.external,
          // },
          // {
          //   title: t('blank'),
          //   path: paths.dashboard.blank,
          //   icon: ICONS.blank,
          // },
          // {
          //   title: t('sampleDashboard'),
          //   // path: paths.dashboard.sampleDashboard,
          //   icon: ICONS.blank,
          // },

          {
            title: t('blank'),
            path: paths.dashboard.blank,
            icon: ICONS.blank,
          },
          // {
          //   title: t('pos checkin and checkout'),
          //   path: paths.dashboard.posCheckingCheckout,
          //   // icon: ICONS.blank,
          // },
        ],
      },
    ],
    [t, userRole]
  );

  return data;
}

