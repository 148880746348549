// import PropTypes from 'prop-types';
// import * as Yup from 'yup';
// import { useEffect, useMemo, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// // @mui
// import LoadingButton from '@mui/lab/LoadingButton';
// import Box from '@mui/material/Box';
// import Alert from '@mui/material/Alert';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import MenuItem from '@mui/material/MenuItem';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import ReactPhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';

// // _mock
// import { USER_STATUS_OPTIONS } from 'src/_mock';
// // assets
// import { countries } from 'src/assets/data';
// import Select from 'react-select';
// import AsyncSelect from 'react-select/async';

// import { useSelector } from 'src/redux/store';

// // hooks
// import { useBoolean } from 'src/hooks/use-boolean';
// import { useDispatch } from 'react-redux';

// // routes
// import { useSearchParams, useRouter } from 'src/routes/hook';
// // auth
// import { useAuthContext } from 'src/auth/hooks';
// // components
// import Iconify from 'src/components/iconify';
// import { useSnackbar } from 'src/components/snackbar';
// import FormProvider, { RHFSelect, RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
// import { endpoints, getFetcherPrams, postFetcher, postPramsFetcher1 } from 'src/utils/axios';
// import { PATH_AFTER_LOGIN, PATH_AFTER_LOGINPOS } from 'src/config-global';

// // ----------------------------------------------------------------------

// export default function CashRegisterReport({
//   currentUser,
//   open,
//   onClose,
//   loginData,
//   onLocationSelect,
//   userDetails,
// }) {
//   const { login } = useAuthContext();

//   const router = useRouter();

//   const searchParams = useSearchParams();

//   const returnTo = searchParams.get('returnTo');

//   const { enqueueSnackbar } = useSnackbar();

//   const loadingSend = useBoolean();

//   const [errorMsg, setErrorMsg] = useState('');

//   const dispatch = useDispatch();

//   const NewUserSchema = Yup.object().shape({
//     first_name: Yup.string().required('First Name is required'),
//   });

//   const defaultValues = useMemo(
//     () => ({
//       first_name: currentUser?.first_name || '',
//     }),
//     [currentUser]
//   );

//   const methods = useForm({
//     resolver: yupResolver(NewUserSchema),
//     defaultValues,
//   });
//   const reduxData = useSelector((state) => state.auth);
//   console.log("Yooo Fire ", reduxData);
//   const {
//     reset,
//     handleSubmit,
//     formState: { isSubmitting },
//     getValues,
//   } = methods;

//   const [selectedLocationId, setSelectedLocationId] = useState(null);

//   const locations = loginData?.user?.usersLocationList || [];

//   console.log('loginData', loginData);
//   console.log('locations', locations);

//   console.log('loginData', loginData?.user?.usersLocationList[0]?.location?.name);

//   const onSubmit = handleSubmit(async (data) => {
//     loadingSend.onTrue();
//     // await login?.(loginData.username, loginData.password);
//     // router.push(returnTo || PATH_AFTER_LOGIN);
//   });

//   // select location
//   const handleLocationChange = (event) => {
//     const locationId = event.target.value;
//     console.log('locationIdDetails', locationId);
//     setSelectedLocationId(locationId);

//     const selectedLocation = locations.find((item) => item.location.id === locationId);

//     if (selectedLocation) {
//       const locationName = selectedLocation.location.name;
//       onLocationSelect(locationId, locationName);
//     }
//   };
//   const loginFunc = async (event) => {
//     if (selectedLocationId === null) {
//       enqueueSnackbar('Please Select Your Branch', {
//         variant: 'error',
//         autoHideDuration: 5000,
//         anchorOrigin: { vertical: 'top', horizontal: 'center' },
//         sx: {
//           width: '400px',
//         },
//       });
//     } else {
//       console.log('Fireloc', selectedLocationId);

//       // loadingSend.onTrue();
//       const res = await login?.(userDetails.username, userDetails.password);
//       console.log('res', res);
//       console.log('ROLE_POSROLE_POS', reduxData);
//       if (reduxData?.user.roles[0] === "ROLE_POS") {
//         router.push(PATH_AFTER_LOGINPOS);
//       } else {
//         router.push(PATH_AFTER_LOGIN);
//       }
//     }
//   };

//   return (
//     <Dialog
//       fullWidth
//       maxWidth={false}
//       open={open}
//       onClose={onClose}
//       PaperProps={{
//         sx: { maxWidth: 600 },
//       }}
//     >
//       <FormProvider methods={methods} onSubmit={onSubmit}>
//         <DialogContent>
//           <Alert variant="outlined" severity="info" sx={{ mb: 3, mt: 3 }}>
//             Location Verification
//           </Alert>

//           <>
//             <RHFSelect
//               size="small"
//               fullWidth
//               name="location_id"
//               onChange={handleLocationChange}
//               label="Select Your Location"
//               style={{ textAlign: 'left' }}
//             >
//               {locations.map((item) => (
//                 <MenuItem key={item.location.id} value={item.location.id}>
//                   {item.location.name}
//                 </MenuItem>
//               ))}
//             </RHFSelect>
//           </>
//         </DialogContent>

//         <DialogActions>
//           <Button variant="outlined" onClick={onClose}>
//             Cancel
//           </Button>

//           <LoadingButton
//             type="submit"
//             variant="contained"
//             loading={isSubmitting}
//             onClick={loginFunc}
//           >
//             Go
//           </LoadingButton>
//         </DialogActions>
//       </FormProvider>
//     </Dialog>
//   );
// }

// CashRegisterReport.propTypes = {
//   currentUser: PropTypes.object,
//   loginData: PropTypes.object,
//   onClose: PropTypes.func,
//   open: PropTypes.bool,
//   onLocationSelect: PropTypes.object,
//   userDetails: PropTypes.object,
// };

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import 'react-phone-number-input/style.css';

// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { useSelector } from 'src/redux/store';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useDispatch } from 'react-redux';

// routes
import { useSearchParams, useRouter } from 'src/routes/hook';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import { endpoints, getFetcherPrams, postFetcher, postPramsFetcher1 } from 'src/utils/axios';
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGINPOS } from 'src/config-global';
import { Divider, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function CashRegisterReport({
  currentUser,
  open,
  onClose,
  loginData,
  onLocationSelect,
  userDetails,
  onConfirmCloseCashRegister,
}) {
  const { login } = useAuthContext();

  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const { enqueueSnackbar } = useSnackbar();

  const loadingSend = useBoolean();

  const [errorMsg, setErrorMsg] = useState('');

  const [cashRegistrySummery, setCashRegistrySummery] = useState('');

  const dispatch = useDispatch();
  
  const [systemDate, setSystemDate] = useState('');


  useEffect(() => {
    const currentDate = new Date().toLocaleDateString('en-GB').split('/').join('-'); // 'en-GB' for dd/MM/yyyy format and then replacing slashes with dashes
    setSystemDate(currentDate);
  }, []);
  


  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      first_name: currentUser?.first_name || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const reduxData = useSelector((state) => state.auth);
  console.log("Yooo Fire ", reduxData);
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;

  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const locations = loginData?.user?.usersLocationList || [];

  console.log('loginData', loginData);
  console.log('locations', locations);

  console.log('loginData', loginData?.user?.usersLocationList[0]?.location?.name);

  const onSubmit = handleSubmit(async (data) => {
    loadingSend.onTrue();
    // await login?.(loginData.username, loginData.password);
    // router.push(returnTo || PATH_AFTER_LOGIN);
  });

  // select location
  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    console.log('locationIdDetails', locationId);
    setSelectedLocationId(locationId);

    const selectedLocation = locations.find((item) => item.location.id === locationId);

    if (selectedLocation) {
      const locationName = selectedLocation.location.name;
      onLocationSelect(locationId, locationName);
    }
  };
  const loginFunc = async (event) => {
    if (selectedLocationId === null) {
      enqueueSnackbar('Please Select Your Branch', {
        variant: 'error',
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        sx: {
          width: '400px',
        },
      });
    } else {
      console.log('Fireloc', selectedLocationId);

      // loadingSend.onTrue();
      const res = await login?.(userDetails.username, userDetails.password);
      console.log('res', res);
      console.log('ROLE_POSROLE_POS', reduxData);
      if (reduxData?.user.roles[0] === "ROLE_POS") {
        router.push(PATH_AFTER_LOGINPOS);
      } else {
        router.push(PATH_AFTER_LOGIN);
      }
    }
  };


  useEffect(() => {
    const getSummery = async () => {
      const params = {
        branch_id: reduxData.selected_location
      }
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const response = await postPramsFetcher1(
        endpoints.posPayment.getCollectionDataSummery,
        null,
        params,
        config
      );
      
      setCashRegistrySummery(response.data);
    }

    getSummery();
  }, [reduxData.selected_location, open]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 600 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {/* <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3, mt: 3 }}>
            Location Verification
          </Alert>

          <>
            <RHFSelect
              size="small"
              fullWidth
              name="location_id"
              onChange={handleLocationChange}
              label="Select Your Location"
              style={{ textAlign: 'left' }}
            >
              {locations.map((item) => (
                <MenuItem key={item.location.id} value={item.location.id}>
                  {item.location.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </>
        </DialogContent> */}
      </FormProvider>

      <DialogContent>
        <Typography sx={{ mt: 3 }} variant="h5">Cash Register Closing - {systemDate}</Typography>
        
        <Alert variant="outlined" severity="warning" sx={{ mb: 3, mt: 4 }}>
          Do you want to close the cash register for {systemDate} ?
        </Alert>

        <Typography sx={{ mt: 3, mb: 2 }} variant="h6">Daily POS Sales Summary</Typography>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">Cash</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{cashRegistrySummery?.cash?.toFixed(3)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Card</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{cashRegistrySummery?.card?.toFixed(3)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Cheque</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{cashRegistrySummery?.cheque?.toFixed(3)}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Total</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">{cashRegistrySummery?.total?.toFixed(3)}</Typography>
            </Grid>
          </Grid>
        </Box>

      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No
        </Button>
        <Button variant="contained" color="error" onClick={onConfirmCloseCashRegister}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CashRegisterReport.propTypes = {
  currentUser: PropTypes.object,
  loginData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onLocationSelect: PropTypes.object,
  userDetails: PropTypes.object,
  onConfirmCloseCashRegister: PropTypes.func,
};

